import React, { useState } from "react";
import "./SideNav.css";
import { RiArrowRightSFill, RiArrowLeftSFill } from "react-icons/ri";

// Import modules
import { NavLink } from "react-router-dom";

// Import Icons
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

const SideNav = () => {
  const [showBalanceDropDown, setShowBalanceDropDown] = useState(false);
  const [showNav, setShowNav] = useState(true);

  return (
    <div className={showNav ? "sidenav-wrapper" : "sidenav-wrapper-minimized"}>
      <div className={`sidenav-toggle ${showNav ? "show" : "hide"}`}>
        {showNav ? (
          <RiArrowLeftSFill
            size="3.3rem"
            onClick={() => setShowNav(!showNav)}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <RiArrowRightSFill
            size="4rem"
            onClick={() => setShowNav(!showNav)}
            style={{ cursor: "pointer", color: "var(--primary)" }}
          />
        )}
      </div>

      <nav className={showNav ? "sidenav" : "sidenav-minimized"}>
        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            "nav-link" + (isActive ? " selected" : "")
          }
        >
          Dashboard
        </NavLink>

        <NavLink
          to="/users"
          className={({ isActive }) =>
            "nav-link" + (!!isActive ? " selected" : "")
          }
        >
          Users
        </NavLink>

        {/* Balance nested links */}
        <div
          className="sidenav-dropdown__label"
          onClick={() => setShowBalanceDropDown((prevState) => !prevState)}
        >
          Balance
          {showBalanceDropDown ? (
            <AiOutlineArrowUp className="sidenav-link-icon" />
          ) : (
            <AiOutlineArrowDown className="sidenav-link-icon" />
          )}
        </div>

        <div
          className={
            showBalanceDropDown ? "sidenav-dropdown" : "sidenav-dropdown-hidden"
          }
        >
          <NavLink
            to="/general-balance"
            className={({ isActive }) =>
              "nav-link" + (!!isActive ? " selected" : "")
            }
          >
            General Balance
          </NavLink>
          <NavLink
            to="/exchange-totals"
            className={({ isActive }) =>
              "nav-link" + (!!isActive ? " selected" : "")
            }
          >
            Exchange Totals
          </NavLink>
        </div>
        <NavLink
          to="/transactions"
          className={({ isActive }) =>
            "nav-link" + (!!isActive ? " selected" : "")
          }
        >
          Tranasctions
        </NavLink>
        <NavLink
          to="/sweeper"
          className={({ isActive }) =>
            "nav-link" + (!!isActive ? " selected" : "")
          }
        >
          Sweeper
        </NavLink>

        <NavLink
          to="/coinStatus"
          className={({ isActive }) =>
            "nav-link" + (!!isActive ? " selected" : "")
          }
        >
          Coin Status
        </NavLink>

        <NavLink
          to="/settings"
          className={({ isActive }) =>
            "nav-link" + (!!isActive ? " selected" : "")
          }
        >
          Settings
        </NavLink>
      </nav>
    </div>
  );
};

export default SideNav;
