import React from "react";
import "./Card.css";
import TableCurrency from "./Currency/TableCurrency.component";

const Card = ({ title, count }) => {
  return (
    <div className="card">
      {title === "userCount" || title === "txCount" ? (
        <>
          {title}: ({count})
        </>
      ) : (
        <>
          {title}:{" "}
          <span>
            $<TableCurrency currency={"usd"}>{count}</TableCurrency>
          </span>
        </>
      )}
    </div>
  );
};

export default Card;
