import btc from "./BTC.svg";
import ltc from "./LTC.svg";
import eth from "./ETH.svg";
import xlm from "./XLM.svg";
import xrp from "./XRP.svg";
import bch from "./BCH.svg";
import busd from "./BUSD.svg";
import bnb from "./BNB.svg";
import enj from "./ENJ.svg";
import reef from "./REEF.svg";
import usdc from "./USDc.svg";
import usdt from "./USDt.svg";
import uni from "./UNI.svg";
import doge from "./DOGE.png";
import shib from "./SHIB.png";
import trx from "./TRX.svg";
import usd from "./USD.svg";
import eur from "./EURO.svg";
import gbp from "./GBP.svg";
import uah from "./UAH.svg";
import rub from "./RUB.svg";
import gel from "./GEL.svg";
import btt from "./BTT.svg";
import ton from "./TON.svg";

/**
 * @example
 * <img src={images.btc} alt="" />
 */
const icons = {
  btc,
  ltc,
  eth,
  xlm,
  xrp,
  bch,
  busd,
  bnb,
  enj,
  reef,
  usdc,
  usdt,
  uni,
  doge,
  shib,
  trx,
  usd,
  eur,
  gbp,
  uah,
  rub,
  gel,
  btt,
  ton,
};

export default icons;
