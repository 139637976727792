import React from 'react';
import './Buttons.css';

export const SubmitBtn = ({ value, ...otherProps }) => (
  <input type='submit' className='submit-btn' value={value} {...otherProps} />
);

export const Button = ({ click, text, ...otherProps }) => (
  <button className='main-btn' {...otherProps} onClick={click}>
    {text}
  </button>
);
