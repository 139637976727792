import React, { useEffect, useState } from "react";
import "./Tranasctions.css";
import axios from "axios";
import { connect } from "react-redux";
import { useTable } from "react-table";
import { Paginator } from "../components/Paginator";
import Loader from "../components/UI/Loader";
import {
  fetchTransactionsStart,
  updateTx,
} from "../redux/transactions/transactions.actions";
import TransactionsFilter from "./transactions/Transactions-filter";
import * as storage from "../providers/storage";
import {
  RiCheckboxLine,
  RiCheckboxBlankLine,
  RiCheckLine,
  RiCloseLine,
} from "react-icons/ri";
import toast from "react-hot-toast";
import { Item } from "react-stately";
import { Select } from "../components/Select/Select";

const Transactions = ({
  fetchTransactions,
  updateTx,
  transactions,
  pages,
  loading,
}) => {
  const [data, setData] = useState([]);
  const [txSelectedPage, setTxSelectedPage] = useState(1);
  const [userId, setUserId] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [currencies, setCurrencies] = useState([]);
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [txId, setTxId] = useState("");
  const [address, setAddress] = useState("");
  const [toggle, setToggle] = useState(false);

  const approveTxHandler = async (txId, network) => {
    const token = storage.get("token");
    try {
      const { data: approvedTx } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URI}manager/transactions/approve`,
        data: {
          txId,
          network,
        },
        headers: {
          authorization: token,
        },
      });

      updateTx(approvedTx);
      setToggle((prevState) => !prevState);
      toast.success("TRANSACTION APPROVED SUCCESSFULLY");
      return;
    } catch (error) {
      toast.error(`ERROR: ${error}`);
    }
  };

  useEffect(() => {
    const data = {
      page: txSelectedPage,
      user_id: userId,
      type,
      currencies,
      from,
      to,
      txId,
      address,
    };
    fetchTransactions(data);
  }, [
    fetchTransactions,
    txSelectedPage,
    userId,
    type,
    currencies,
    from,
    to,
    txId,
    address,
  ]);

  useEffect(() => {
    const _transactions = JSON.parse(JSON.stringify(transactions));
    const _data = _transactions.map((tr) => {
      tr.price = tr.price.usd;

      tr.type !== "withdrawal" ? (
        <></>
      ) : (
        (tr.is_approved = tr.is_approved ? (
          <div className="approved" onClick={() => console.log("approved")}>
            <RiCheckLine size="3rem" color="green" />
          </div>
        ) : (
          <div className=".not_approved">
            <Select
              label="Action"
              defaultSelectedKey="approve"
              items={[
                { id: "approve", name: "Approve" },
                { id: "cancel", name: "Cancel" },
              ]}
              onSelectionChange={(key) =>
                key === "approve"
                  ? approveTxHandler(tr.id, tr.network)
                  : console.log("cancel")
              }
            >
              {(item) => (
                <Item>
                  {item.id === "approve" ? (
                    <span className="approval_item">
                      <RiCheckLine />
                      {item.name}
                    </span>
                  ) : (
                    <span className="approval_item">
                      <RiCloseLine />
                      {item.name}
                    </span>
                  )}
                </Item>
              )}
            </Select>
          </div>
        ))
      );
      return tr;
    });
    setData(_data);
  }, [transactions, toggle]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Is Approved",
        accessor: "is_approved",
      },
      {
        Header: "User",
        accessor: "user_id",
      },
      {
        Header: "Currency",
        accessor: "currency",
      },
      {
        Header: "Amount",
        accessor: "amount", // accessor is the "key" in the data
      },
      {
        Header: "Price",
        accessor: "price",
      },

      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Type",
        accessor: "type",
      },

      {
        Header: "Date",
        accessor: "createdAt",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // Move this to tx util/actions
  const handleTxClick = ({ selected }) => setTxSelectedPage(selected + 1);

  const handleTypeChange = (tp) => {
    if (tp === type) {
      setType(null);
    } else {
      setType(tp);
    }
  };
  const handleCurrChange = ({ target: { value, checked } }) => {
    let updatedCurrencies;
    if (checked) {
      updatedCurrencies = [...currencies, value];
    } else {
      updatedCurrencies = currencies.filter((curr) => curr !== value);
    }
    setCurrencies(updatedCurrencies);
  };
  const handleChangeFrom = (date) => {
    setFrom(date);
  };
  const handleChangeTo = (date) => {
    setTo(date);
  };
  const handleUserId = (e) => {
    setUserId(e.target.value);
  };
  const handleTxId = (e) => {
    setTxId(e.target.value);
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  // if (loading) return <Loader />;

  return (
    <div className="users page">
      <TransactionsFilter
        handleTypeChange={handleTypeChange}
        type={type}
        handleCurrChange={handleCurrChange}
        currActive={currencies.length > 0}
        handleChangeFrom={handleChangeFrom}
        from={from}
        handleChangeTo={handleChangeTo}
        to={to}
        handleUserId={handleUserId}
        userId={userId}
        handleTxId={handleTxId}
        txId={txId}
        handleAddress={handleAddress}
        address={address}
      />

      {/* Start Table */}

      <table {...getTableProps()}>
        <thead className="tableHeader">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="tableHeader">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="tableHeader">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                <Loader />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          backgroundColor:
                            cell.row.id % 2 === 0
                              ? "rgba(25, 28, 29, 0.5)"
                              : "rgba(224, 227, 227, 0.02)",
                        }}
                        className="tableCell"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {/* End Table */}

      <Paginator
        handleClick={handleTxClick}
        pages={pages || 1}
        currentPage={txSelectedPage - 1}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.transactions.transactions,
  pages: state.transactions.pages,
  loading: state.transactions.loadingTxs,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransactions: (data) => dispatch(fetchTransactionsStart(data)),
  updateTx: (data) => dispatch(updateTx(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Transactions);
