// @ts-check

import currencies from "assets/currencies";
import CurrencyColors from "assets/data/currency-colors";
import TextButton from "components/InputComponents/Buttons/TextButton";
import { useLocale } from "context/LanguageContext";
import * as React from "react";
import { RiArrowRightCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import styles from "./OperationButton.module.css";

/**
 * @template T
 * @typedef CommonProps
 * @property {T} type
 * @property {number} amount
 * @property {import('../../assets/data/types').NetworkType} network
 * @property {string} id
 */

/**
 * @template T
 * @typedef {CommonProps<T> & {currency: import("../../assets/currencies/currencies").Currency}} NotExchangeProps
 */

/**
 * @template T
 * @typedef {CommonProps<T> & {currency: `${import('../../assets/currencies/currencies').Currency} -> ${import('../../assets/currencies/currencies').Currency}` }} ExchangeProps
 */

/**
 * @template {"exchange" | "invoice" | "deposit" | "withdrawal" | "P2P_deposit" | "P2P_withdrawal"} T
 * @param {T extends "exchange" ? ExchangeProps<T> : NotExchangeProps<T>} props
 **/
function OperationButton({ type, amount, currency, network, id }) {
  const { LL } = useLocale();
  const navigate = useNavigate();
  const navigateInvoice = useNavigate();

  if (type === "exchange") {
    const [from, to] =
      /** @type {[import('../../assets/currencies/currencies').Currency, import('../../assets/currencies/currencies').Currency]} */ (
        currency.split(" -> ")
      );

    return (
      <TextButton
        aria-label={LL.transactionsTable.operationColumn.swapLabel({
          from,
          to,
        })}
        onPress={async () => {
          navigate(`/explorer/exchange/${id}`);
        }}
        className={styles.button}
      >
        <CurrencyImage currency={from} />
        <span
          style={{
            color: `${CurrencyColors[from].background}`,
          }}
          className={styles.currency}
        >
          {from.toLocaleUpperCase()}
        </span>
        <span style={{ marginRight: "1.4rem" }}>
          <RiArrowRightCircleLine />{" "}
        </span>
        <CurrencyImage currency={to} />
        <span
          style={{
            margin: ".8rem",
            color: `${CurrencyColors[to].background}`,
          }}
        >
          {to.toLocaleUpperCase()}
        </span>
        <span>{LL.transactionsTable.operationColumn.swapVisibleText()}</span>
      </TextButton>
    );
  } else if (type === "invoice") {
    return (
      <TextButton
        aria-label={LL.transactionsTable.operationColumn.invoiceLabel({
          currency,
        })}
        onPress={async () => {
          navigate(`/payment-requested/${id}`);
        }}
        className={styles.button}
      >
        <CurrencyImage currency={currency} />
        <span
          style={{
            color: `${CurrencyColors[currency].background}`,
          }}
          className={styles.currency}
        >
          {currency.toLocaleUpperCase()}
        </span>
        <span>{LL.transactionsTable.operationColumn.invoiceVisibleText()}</span>
      </TextButton>
    );
  } else if (type === "deposit") {
    return (
      <TextButton
        aria-label={LL.transactionsTable.operationColumn.depositLabel({
          currency,
        })}
        onPress={async () => {
          navigate(`/explorer/${currency}/${network}/${id}`);
        }}
        className={styles.button}
      >
        <CurrencyImage currency={currency} />
        <span
          style={{
            color: `${CurrencyColors[currency].background}`,
          }}
          className={styles.currency}
        >
          {currency.toLocaleUpperCase()}
        </span>
        <span>{LL.transactionsTable.operationColumn.depositVisibleText()}</span>
      </TextButton>
    );
  } else if (type === "withdrawal") {
    return (
      <TextButton
        aria-label={LL.transactionsTable.operationColumn.withdrawalLabel({
          currency,
        })}
        onPress={async () => {
          navigate(`/explorer/${currency}/${network}/${id}`);
        }}
        className={styles.button}
      >
        <CurrencyImage currency={currency} />
        <span
          style={{
            color: `${CurrencyColors[currency].background}`,
          }}
          className={styles.currency}
        >
          {currency.toLocaleUpperCase()}
        </span>
        <span>
          {LL.transactionsTable.operationColumn.withdrawalVisibleText()}
        </span>
      </TextButton>
    );
  } else if (type === "P2P_deposit") {
    return (
      <TextButton
        aria-label={LL.transactionsTable.operationColumn.withdrawalLabel({
          currency,
        })}
        onPress={async () => {
          navigate(`/explorer/${currency}/${network}/${id}`);
        }}
        className={styles.button}
      >
        <CurrencyImage currency={currency} />
        <span
          style={{
            color: `${CurrencyColors[currency].background}`,
          }}
          className={styles.currency}
        >
          {currency.toLocaleUpperCase()}
        </span>
        <span>
          P2P {LL.transactionsTable.operationColumn.depositVisibleText()}
        </span>
      </TextButton>
    );
  } else if (type === "P2P_withdrawal") {
    return (
      <TextButton
        aria-label={LL.transactionsTable.operationColumn.withdrawalLabel({
          currency,
        })}
        onPress={async () => {
          navigate(`/explorer/${currency}/${network}/${id}`);
        }}
        className={styles.button}
      >
        <CurrencyImage currency={currency} />
        <span
          style={{
            color: `${CurrencyColors[currency].background}`,
          }}
          className={styles.currency}
        >
          {currency.toLocaleUpperCase()}
        </span>
        <span>
          P2P {LL.transactionsTable.operationColumn.withdrawalVisibleText()}
        </span>
      </TextButton>
    );
  }
  // TODO: P2P
}

function CurrencyImage({ currency }) {
  return currencies[currency] ? (
    <img src={currencies[currency]} alt="" width={24} height={24} />
  ) : (
    currency.toLocaleUpperCase()
  );
}

export default OperationButton;
