// @ts-check

import * as React from "react";
import ButtonBase from "./ButtonBase";
import styles from "./IconButton.module.css";
import useThemeVariables from "hooks/useThemeVariables";
import { hexToRGBA } from "utils/colorUtils";
import { mergeClassNames } from "../../../utils/classNames";
import useDefaultRef from "hooks/useDefaultRef";

/**
 * @typedef IconButtonProps
 *
 * @property {any} children
 * @property {string} aria-label
 * Defines a string value that labels the current element. (for icons and images)
 *
 * @property {string} [id]
 * @property {string} [className]
 * @property {import("./ButtonBase").ClassNames & {buttonContent?: string}} [classNames]
 * @property {boolean} [attachesBackground] whether to do background attach animation on hover
 * @property {boolean} [pressesBackground] whether to do background press animation on press
 * @property {boolean} [isDisabled]
 * @property {(e: import("@react-types/shared").PressEvent) => void} [onPressStart]
 * @property {(isPressed: boolean) => void} [onPressChange] Handler that is called when the press state changes.
 * @property {(e: import("@react-types/shared").PressEvent) => void} [onPressEnd]
 * @property {(e: import("@react-types/shared").PressEvent) => void} [onPress]
 * @property {(e: import("@react-types/shared").KeyboardEvent) => void} [onKeyDown]
 * @property {(e: import("@react-types/shared").KeyboardEvent) => void} [onKeyUp]
 * @property {(isFocused: boolean) => void} [onFocusChange] Handler that is called when the element's focus status changes.
 * @property {(e:React.FocusEvent<Element, Element>) => void} [onBlur]
 * @property {boolean} [autoFocus]
 * @property {'button' | 'submit'| 'reset'} [type] The behavior of the button when used in an HTML form.
 *
 * @property {"a" | "button"} [elementType]
 * @property {string} [href] href for anchor tag
 * @property {string} [target] target for anchor tag
 * @property {string} [rel] The relationship between the linked resource and the current page when elementType="a"
 *
 *
 * @property {boolean} [excludeFromTabOrder]
 * Whether to exclude the element from the sequential tab order.
 * If true, the element will not be focusable via the keyboard by tabbing.
 * This should be avoided except in rare scenarios where an alternative means
 * of accessing the element or its functionality via the keyboard is available.
 *
 * @property {boolean | "true" | "false"} [aria-expanded]
 * Indicates whether the element, or another grouping element it controls,
 * is currently expanded or collapsed.
 *
 * @property {boolean | "dialog" | "menu" | "grid" | "listbox" | "tree" | "true" | "false"} [aria-haspopup]
 * Indicates the availability and type of interactive popup element,
 * such as menu or dialog, that can be triggered by an element.
 *
 * @property {string} [aria-controls]
 * Identifies the element (or elements) whose contents or presence are controlled by the current element.
 *
 * @property {boolean | "true" | "false" | "mixed"} [aria-pressed]
 * Indicates the current "pressed" state of toggle buttons.
 *
 * @property {string} [aria-labelledby]
 * Identifies the element (or elements) that labels the current element.
 *
 * @property {string} [aria-describedby]
 * dentifies the element (or elements) that describes the object.
 *
 * @property {string} [aria-details]
 * Identifies the element (or elements) that provide a detailed, extended description for the object.
 *
 * @property {(...args: any[]) => any} [onClick]
 * Should not be used in most cases. is an alias for onPress and is here to bridge the gap
 * for the libraries that assume onClick
 *
 * @param {IconButtonProps} props
 */
function IconButtonWithRef({ children, classNames, ...props }, parentRef) {
  const ref = useDefaultRef(parentRef);

  const { colorPrimary, colorPrimaryContainer } = useThemeVariables();

  const mergedClassnames = mergeClassNames(classNames, styles);

  return (
    <ButtonBase
      ref={ref}
      classNames={mergedClassnames}
      whileTap={{ scale: 0.97 }}
      isolateElevation
      {...props}
    >
      <span className={mergedClassnames.buttonContent}>{children}</span>
    </ButtonBase>
  );
}

const IconButton = React.forwardRef(IconButtonWithRef);
export default IconButton;
