import {
  FETCH_TOTAL_BALANCE_START,
  FETCH_TOTAL_BALANCE_SUCCESS,
  FETCH_TOTAL_BALANCE_FAIL,
} from './balance.types';

// Total balance acions
export const fetchTotalBalanceStart = () => ({
  type: FETCH_TOTAL_BALANCE_START,
});

export const fetchTotalBalanceSuccess = (payload) => ({
  type: FETCH_TOTAL_BALANCE_SUCCESS,
  payload,
});

export const fetchTotalBalanceFail = (payload) => ({
  type: FETCH_TOTAL_BALANCE_FAIL,
  payload,
});
