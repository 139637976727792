import "./App.css";
import "./styles";

import { Toaster } from "react-hot-toast";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import SideNav from "./components/SideNav";
import Header from "./components/Header";

import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import User from "./pages/User";
import Transactions from "./pages/Transactions";
import Sweeper from "./pages/Sweeper";
import CoinStatus from "./pages/CoinStatus";
import Settings from "./pages/Settings";
// balance pages
import GeneralBalance from "./pages/balance-pages/GeneralBalance";
import ExchangeTotals from "./pages/balance-pages/ExchangeTotals";
import React from "react";
import AppLayout from "layouts/AppLayout.layout";

function App() {
  let location = useLocation();

  return (
    <>
      <div className="background-wrapper"></div>
      <div className="App">
        <Toaster
          // @ts-ignore
          autoClose={5000}
        />
        {/* Declare routes */}

        {location.pathname !== "/" && <Header />}
        <div className="main-wrapper">
          {location.pathname !== "/" && <SideNav />}
          <Routes>
            <Route element={<AppLayout />}>
              <Route path="/" element={<Landing />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user/:id"
                element={
                  <PrivateRoute>
                    <User />
                  </PrivateRoute>
                }
              />

              <Route
                path="/general-balance"
                element={
                  <PrivateRoute>
                    <GeneralBalance />
                  </PrivateRoute>
                }
              />
              <Route
                path="/exchange-totals"
                element={
                  <PrivateRoute>
                    <ExchangeTotals />
                  </PrivateRoute>
                }
              />

              <Route
                path="/transactions"
                element={
                  <PrivateRoute>
                    <Transactions />
                  </PrivateRoute>
                }
              />

              <Route
                path="/sweeper"
                element={
                  <PrivateRoute>
                    <Sweeper />
                  </PrivateRoute>
                }
              />

              <Route
                path="/coinStatus"
                element={
                  <PrivateRoute>
                    <CoinStatus />
                  </PrivateRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
