import * as React from "react";
import {
  QueryClient,
  QueryClientProvider as QUERY_CLIENT_PROVIDER,
} from "@tanstack/react-query";

export const queryClient = new QueryClient();

export function QueryClientProvider({ children }) {
  return (
    <QUERY_CLIENT_PROVIDER client={queryClient}>
      {children}
    </QUERY_CLIENT_PROVIDER>
  );
}
