import { takeLatest, call, put, all } from "redux-saga/effects";
import axios from "axios";
import * as storage from "../../providers/storage";
import big from "bigjs-literal/macro";
import { FETCH_TOTAL_BALANCE_START } from "./balance.types";

import {
  fetchTotalBalanceSuccess,
  fetchTotalBalanceFail,
} from "./balance.actions";

const ignore_currencies = ["zrx", "omg", "vet", "chz", "snx", "link", "mkr"];

export function* fetchTotalBalanceAsync() {
  const token = storage.get("token");

  try {
    const { data: walletData } = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URI}statistics/total-wallet-balances`,
      headers: {
        Authorization: token,
      },
    });

    const { data: pbxData } = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URI}statistics/total-paybitx-balances`,
      headers: {
        Authorization: token,
      },
    });

    const {
      data: {
        data: { addressBalanceTotals, exchangeTotals, totalAccumulated },
      },
    } = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URI}manager/statistics/balance`,
      headers: {
        Authorization: token,
      },
    });

    const { data: rates } = yield axios({
      method: "get",
      url: `https://api.paybitx.com/exchange/rates`,
    });

    for (let i = 0; i < ignore_currencies.length; i += 1) {
      let curr = ignore_currencies[i];
      delete pbxData[curr];
    }

    let sumWalletBalance = 0;
    let sumExchangeTotals = 0;
    let sumAddressTotals = 0;
    let sumAccumulatedDeposits = 0;
    let sumNetGain = 0;
    Object.keys(pbxData).forEach((coin) => {
      if (!exchangeTotals[coin]) exchangeTotals[coin] = 0;
      pbxData[coin].currency = coin;
      pbxData[coin].total = walletData[coin] || 0;
      pbxData[coin].exchange_totals = exchangeTotals[coin] || 0;
      pbxData[coin].address_totals = addressBalanceTotals[coin] || 0;
      pbxData[coin].total_accumulated = totalAccumulated?.[coin] || 0;
      pbxData[coin].netGain =
        +walletData[coin] -
          +pbxData[coin].total_confirmed -
          +pbxData[coin].total_unconfirmed +
          +exchangeTotals[coin] || 0;

      sumWalletBalance += +big`${Number(pbxData[coin].total)} * ${
        rates[coin]["usd"]
      }`;
      sumExchangeTotals += +big`${Number(pbxData[coin].exchange_totals)} * ${
        rates[coin]["usd"]
      }`;
      sumAddressTotals += +big`${Number(pbxData[coin].address_totals)} * ${
        rates[coin]["usd"]
      }`;
      sumAccumulatedDeposits += +big`${Number(
        pbxData[coin].total_accumulated
      )} * ${rates[coin]["usd"]}`;
      sumNetGain += +big`${Number(pbxData[coin].netGain)} * ${
        rates[coin]["usd"]
      }`;
    });
    pbxData["SUM"] = {
      currency: "SUM (USD)",
      total: sumWalletBalance,
      total_confirmed: 0,
      total_unconfirmed: 0,
      exchange_totals: sumExchangeTotals,
      address_totals: sumAddressTotals,
      total_accumulated: sumAccumulatedDeposits,
      netGain: sumNetGain,
    };

    yield put(fetchTotalBalanceSuccess(pbxData));
  } catch (error) {
    console.log({ error });
    yield put(fetchTotalBalanceFail(error));
  }
}

export function* fetchotalBalance() {
  yield takeLatest(FETCH_TOTAL_BALANCE_START, fetchTotalBalanceAsync);
}

export function* balanceSagas() {
  yield all([call(fetchotalBalance)]);
}
