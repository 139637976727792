// @ts-check

import * as React from "react";
import Currency from "../Currency/Currency.component";
import { useRatesQuery } from "queries/rates";
import big from "bigjs-literal/macro";
import { useCurrencyAndThemeContext } from "context/CurrencyAndThemeContext";

// TODO: get this value from state

/**
 * @typedef {import("components/Currency/Currency.component").CurrencyProps} ConversionCurrencyProps
 * @param {ConversionCurrencyProps} props
 */
export default function ConversionCurrency({ children, currency, ...props }) {
  const { data: rates } = useRatesQuery();
  const { conversionCurrency } = useCurrencyAndThemeContext();
  try {
    return (
      <>
        {React.Children.map(children, (child) => {
          if (typeof child === "number") {
            return (
              <Currency currency={conversionCurrency} {...props}>
                {rates
                  ? +big`${child} * ${rates[currency][conversionCurrency]}`
                  : null}
              </Currency>
            );
          }
          return child;
        })}
      </>
    );
  } catch (error) {
    console.log(error);
  }
}
