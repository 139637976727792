/**
 * merge classnames from css modules
 *
 * @param  {...{[index: string]: string}} args
 *
 * @example
 * mergeClassNames(
 *  {container: "cntr1", isDisabled: "--dsbl"},
 *  {container: "cntr2", isDisabled: "dsbl", isKind: "yep"},
 * )
 * // -> {container: "cntr1 cntr2", isDisabled: "--dsbl dsbl", isKind: "yep"}
 *
 * // real-world usecase:
 * import css1Styles from "css1.module.css"
 * import css2styles from "css2.module.css"
 * const classNames = mergeClassNames(css1Styles, css2Styles)
 */
export function mergeClassNames(...args) {
  // Start with a base clone of the first argument. This is a lot faster than starting
  // with an empty object and adding as we go.
  const result = { ...args[0] };
  for (let i = 1; i < args.length; i++) {
    const classNames = args[i];
    for (const key in classNames) {
      const a = result[key];
      const b = classNames[key];

      if (a) {
        result[key] = a + " " + b;
      } else {
        result[key] = b;
      }
    }
  }
  return result;
}
