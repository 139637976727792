import {
    FETCH_DASHBOARD_DATA_START,
    FETCH_DASHBOARD_DATA_SUCCESS,
    FETCH_DASHBOARD_DATA_FAIL,
  } from './dashboard.types';
  
  // Total balance acions
  export const fetchdashboardDataStart = () => ({
    type: FETCH_DASHBOARD_DATA_START,
  });
  
  export const fetchdashboardDataSuccess = (payload) => ({
    type: FETCH_DASHBOARD_DATA_SUCCESS,
    payload,
  });
  
  export const fetchdashboardDataFail = (payload) => ({
    type: FETCH_DASHBOARD_DATA_FAIL,
    payload,
  });
  