// @ts-check

import * as React from "react";
import { useDialog } from "react-aria";
import styles from "./Dialog.module.css";
import cx from "classnames";

/**
 * A dialog is an overlay shown above other content in an application.
 * Handles correctly labeling the container and the optional title elements,
 * also focusing the container on mount and restoring the focus to the trigger element on unmount.
 *
 * Dialogs should be used within a modal or popover to display content obove other elements.
 *
 * @param {DialogProps} props
 */
export default function Dialog({
  role = "dialog",
  title,
  children,
  classNames = {},
  ...props
}) {
  let ref = React.useRef();
  let { dialogProps, titleProps } = useDialog({ ...props, role }, ref);

  return (
    <div
      {...dialogProps}
      className={cx(classNames.container, styles.container)}
      ref={ref}
    >
      {title && (
        <h2 {...titleProps} className={cx(classNames.title, styles.title)}>
          {title}
        </h2>
      )}
      {children}
    </div>
  );
}

/**
 * @typedef {LocalProps & import('../../../assets/data/types').AriaLabelingProps} DialogProps
 */

/**
 * @typedef LocalProps
 *
 * @property {"dialog" | "alertdialog"} [role="dialog"]
 * The accessibility role for the dialog. Controlls how the modal is announced
 * to screen readers. An "alertdialog" is specific type of dialog that interrupts the
 * flow to give users information and no more than three buttons to acknowledge and/or
 * accept and/or decline the given information. A "dialog" is more general element that
 * also interupts the flow but can do various things and can hold form elements.
 * {@link https://stackoverflow.com/questions/53349338/what-is-the-difference-between-dialog-and-alertdialog}
 *
 * @property {React.ReactNode} [children]
 * content displayed below the title of the dialog
 *
 * @property {React.ReactNode} [title]
 *
 * @property {ClassNames} [classNames]
 */

/**
 * @typedef ClassNames
 * @property {string} [container]
 * @property {string} [title]
 **/
