import React from 'react';
import './LandingHeader.css';

import Logo from '../../assets/pictures/logo.png';

const LandingHeader = () => {
  return (
    <div className='landing-header'>
      <div className='landing-header__logo'>
        <img src={Logo} alt='header-logo' />
      </div>
    </div>
  );
};

export default LandingHeader;
