import { combineReducers } from "redux";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import balanceReducer from "./balance/balance.reducer";
import dashboardReducer from "./dashboard/dashboard.reducer";
import usersReducer from "./users/users.reducer";
import transactionsReducer from "./transactions/transactions.reducer";
import exchangeReducer from "./exchangeTotals/exchange.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({
  balance: balanceReducer,
  exchange: exchangeReducer,
  dashboard: dashboardReducer,
  users: usersReducer,
  transactions: transactionsReducer,
});

export default persistReducer(persistConfig, rootReducer);
