// @ts-check

import * as React from "react";
import cx from "classnames";
import ValidationAndHints from "./ValidationAndHints.component";

/**
 * @typedef ClassNames
 * @property {string | string[]} [description]
 * @property {string | string[]} [errorMessage]
 * @property {string | string[]} [label]
 * @property {string | string[]} [input]
 *
 * @param {object} props
 * @param {ClassNames} props.classNames
 * @param {"valid" | "invalid"} [props.validationState]
 * @param {boolean} [props.isDisabled]
 * @param {React.ReactNode} [props.label]
 * @param {"input" | "textarea"} [props.inputElementType]
 * @param {React.ReactNode} [props.description]
 * @param {React.ReactNode} [props.errorMessage]
 * @param {React.LabelHTMLAttributes<HTMLLabelElement>} props.labelProps
 * @param {React.TextareaHTMLAttributes<HTMLTextAreaElement> | React.InputHTMLAttributes<HTMLInputElement>} props.inputProps
 * @param {React.HTMLAttributes<HTMLElement>} props.descriptionProps
 * @param {React.HTMLAttributes<HTMLElement>} props.errorMessageProps
 */
function FieldBase(
  {
    classNames,
    inputElementType = "input",
    validationState,
    isDisabled,
    label,
    description,
    errorMessage,
    labelProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
  },
  ref
) {
  React.useEffect(() => {
    if (!ref) throw new Error("Must provide ref to FieldBase");
  });

  const modifiers = cx({
    "--isTextArea": inputElementType === "textarea",
    "--hasError": errorMessage,
    "--isValid": validationState === "valid",
    "--isInvalid": validationState === "invalid",
    "--isDisabled": isDisabled,
  });

  return (
    <>
      {label && (
        <label {...labelProps} className={cx(classNames.label, modifiers)}>
          {label}
        </label>
      )}

      {React.createElement(inputElementType, {
        ...{ rows: inputElementType === "textarea" ? 3 : undefined },
        ...inputProps,
        className: cx(classNames.input, modifiers),
        ref,
      })}

      <ValidationAndHints
        classNames={classNames}
        {...{
          validationState,
          errorMessage,
          errorMessageProps,
          description,
          descriptionProps,
          isDisabled,
        }}
      />
    </>
  );
}

export default React.forwardRef(FieldBase);
