import React, { useEffect } from 'react';
import './Landing.css';
import { useNavigate } from 'react-router-dom';
import * as storage from '../providers/storage';

import LandingHeader from './landing/LandingHeader';
import LoginForm from './landing/LoginForm';

const Landing = () => {
  let navigate = useNavigate();

  useEffect(() => {
    const token = storage.get('token');
    if (token) navigate('/dashboard');
  }, [navigate]);

  return (
    <div className='landing-page'>
      <LandingHeader />
      <LoginForm />
    </div>
  );
};

export default Landing;
