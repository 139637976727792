import * as React from "react";
import { useButton, mergeProps, useFocusRing } from "react-aria";
import "./Styles.css";

export const Button = React.forwardRef(function (props, ref) {
  let { buttonProps } = useButton(props, ref);
  let { focusProps, isFocusVisible } = useFocusRing();

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={`${isFocusVisible ? "focus-ring" : ""}`}
    >
      {props.children}
    </button>
  );
});
