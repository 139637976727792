import React from 'react';
import { Navigate } from 'react-router-dom';
import * as storage from '../providers/storage';

const PrivateRoute = ({ children }) => {
  const tokenExists = storage.get('token');

  return tokenExists ? children : <Navigate to='/' />;
};

export default PrivateRoute;
