import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "./queries/queryClient";
import { LanguageProvider } from "./context/LanguageContext";
import { CurrencyAndThemePovider } from "context/CurrencyAndThemeContext";

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider>
      <BrowserRouter>
        <LanguageProvider>
          <CurrencyAndThemePovider>
            <React.StrictMode>
              <PersistGate persistor={persistor}>
                <App />
              </PersistGate>
            </React.StrictMode>
          </CurrencyAndThemePovider>
        </LanguageProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
