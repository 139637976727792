// @ts-check
import cx from "classnames";
import { useLocale } from "context/LanguageContext";
import * as React from "react";
import { Level } from "react-accessible-headings";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import styles from "./AppLayout.module.css";
import useThemeVariables from "hooks/useThemeVariables";

export default function AppLayout() {
  const { locale } = useLocale();
  const { isDarkTheme } = useThemeVariables();

  return (
    <div
      className={cx(styles.container, {
        "--is-dark-theme": isDarkTheme,
        "--is-light-theme": !isDarkTheme,
        "--locale-en": locale === "en",
        "--locale-ka": locale === "ka",
      })}
    >
      <Outlet />
    </div>
  );
}
