import { add } from "date-fns";
import TypesafeI18n, { useI18nContext } from "../i18n/i18n-react";
import { baseLocale, locales } from "../i18n/i18n-util";
import * as React from "react";
import {
  detectLocale,
  navigatorDetector,
  initDocumentCookieDetector,
} from "typesafe-i18n/detectors";
import { Helmet } from "react-helmet";
import { loadLocaleAsync } from "../i18n/i18n-util.async";
import { setDefaultOptions } from "date-fns/esm";
import { useLocale as useTrueLocale } from "react-aria";
// TODO: Really wanted this to load asynchronously but can't.
// Best solution would be to load "ka" asynchronously then wrap datefn's format to use intl by default and
// use datefn for unsupported locales such as "ka"
import { ka, enGB } from "date-fns/locale";

const LANG_COOKIE = "lang";

const dateTimeLocales = {
  en: enGB,
  ka: ka,
};

function LanguageProviderHelper({ children }) {
  const { locale } = useLocale();

  return (
    <>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      {children}
    </>
  );
}

export const LanguageProvider = React.memo(
  /** @param {{children: React.ReactNode}} props */
  ({ children }) => {
    const defaultLocale = detectLocale(
      // Fallback locale for when user's default language is not supported
      baseLocale,
      // Available locales
      locales,
      // Try to read the locale from a 'lang' cookie.
      // This is set when client updates prefered language inside our application.
      // Cookie based solution is similar to how nextjs handles locales
      initDocumentCookieDetector(LANG_COOKIE),
      // If lang cookie not found, use cliente's prefered language from navigator
      navigatorDetector
    );

    const [localeLoading, setLocaleLoading] = React.useState(true);
    React.useEffect(() => {
      setLocaleLoading(true);

      setDefaultOptions({ locale: dateTimeLocales[defaultLocale] });

      loadLocaleAsync(defaultLocale)
        .then(() => {
          setLocaleLoading(false);
        })
        .catch(() => console.error("Could not load locale: " + defaultLocale));
    }, [defaultLocale]);

    if (localeLoading) {
      return null;
    }

    // NOTE: LanguageProvider doesn't rerun when locales change
    return (
      <TypesafeI18n locale={"en"}>
        <LanguageProviderHelper>{children}</LanguageProviderHelper>
      </TypesafeI18n>
    );
  }
);

export function useLocale() {
  const { LL, locale, setLocale: _setLocale } = useI18nContext();

  /**
   * Update 'lang' cookie, load new locale, and update state
   * @param {import('i18n/i18n-types').Locales} locale
   **/
  const setLocale = async (locale) => {
    const expires = add(new Date(), {
      years: 2,
    });
    document.cookie = `${LANG_COOKIE}=${locale}; expires=${expires}; path=/`;

    try {
      await loadLocaleAsync(locale);
      setDefaultOptions({ locale: dateTimeLocales[locale] });

      _setLocale(locale);
    } catch {
      console.error("could not load locale: " + locale);
    }
  };

  const { locale: trueLocale } = useTrueLocale();

  return {
    LL,
    /** Locale that we support.  */
    locale,
    /** User's true locale even if we don't support it  */
    trueLocale,
    setLocale,
  };
}
