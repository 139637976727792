import React from 'react';
import './Paginator.css';
import ReactPaginate from 'react-paginate';

export const Paginator = ({ pages, currentPage, handleClick }) => {
  return (
    <div>
      <ReactPaginate
        forcePage={currentPage}
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handleClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
    </div>
  );
};
