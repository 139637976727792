import * as React from "react";
import { useSelectState } from "react-stately";
import { useSelect, HiddenSelect } from "react-aria";
import { Button } from "./Button";
import { ListBox } from "./ListBox";
import { Popover } from "./Popover";
import { RiArrowDownLine } from "react-icons/ri";
import { AiOutlineBars } from "react-icons/ai";
import "./Styles.css";

export function Select(props) {
  let state = useSelectState(props);
  let ref = React.useRef(null);
  let { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref
  );

  let popoverRef = React.useRef(null);
  let listBoxRef = React.useRef(null);
  React.useEffect(() => {
    if (
      state.isOpen &&
      popoverRef.current &&
      ref.current &&
      !popoverRef.current.style.top
    ) {
      // Position popover so the text of selected item exactly
      // lines up with the text in the button.
      let selected = listBoxRef.current.querySelector(
        "[aria-selected=true] span"
      );
      let value = ref.current.querySelector("span");
      let listBox = listBoxRef.current;
      let popoverRect = popoverRef.current.getBoundingClientRect();

      // The minimum y position is 5px from the top of the
      // browser window (relative to the button).
      let minY = -popoverRect.top + 5;

      // The position of the popover relative to the button
      // is such that the text lines up (without constraining).
      let y = value.offsetTop - selected.offsetTop;
      let x = value.offsetLeft - selected.offsetLeft;

      // Constrain to minimum.
      popoverRef.current.style.top = `${Math.max(minY, y)}px`;
      popoverRef.current.style.left = `${x}px`;

      // If actual y position is less than the minimum,
      // the listbox must be scrolled so the item still lines up.
      if (y < minY) {
        // Scroll position is the difference.
        let scrollTop = minY - y;

        // The maximum scroll position of the listbox is the
        // height of the content minus the maximum height.
        let listHeight = listBox.getBoundingClientRect().height;
        let lastPage = listHeight - window.innerHeight - 10;

        // If computed scroll position is past the maximum,
        // adjust the maximum height and scroll position
        // so the text still lines up.
        if (scrollTop > lastPage) {
          let maxY = scrollTop - lastPage;

          // Adjust scroll position to be at the bottom, regardless
          // of any padding that might exist. Update maxY based
          // on the difference.
          lastPage = listHeight - (window.innerHeight - maxY);
          maxY -= lastPage - scrollTop;
          scrollTop = lastPage;

          // Max height is less than the height of the window, so
          // should be fixed.
          listBox.style.maxHeight = `${window.innerHeight - maxY}px`;
        } else {
          // Max height should grow when the window resizes.
          listBox.style.maxHeight = `calc(100vh - 10px)`;
        }

        listBox.scrollTop = scrollTop;
      } else {
        // Max height should be less than the height of the window
        // if selected item is near the top, so it still lines up.
        listBox.style.maxHeight = `calc(100vh - 10px - ${y - minY}px)`;
      }
    }
  });

  return (
    <div className="select">
      <HiddenSelect
        state={state}
        triggerRef={ref}
        label={props.label}
        name={props.name}
      />
      <div className="container">
        <Button {...triggerProps} ref={ref}>
          <span {...valueProps}></span>
          <AiOutlineBars aria-hidden="true" />
        </Button>
        {state.isOpen && (
          <Popover
            popoverRef={popoverRef}
            isOpen={state.isOpen}
            onClose={state.close}
          >
            <ListBox {...menuProps} state={state} listBoxRef={listBoxRef} />
          </Popover>
        )}
      </div>
    </div>
  );
}
