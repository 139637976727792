// @ts-check
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

/**
 * @typedef {{
 *   [currency in import('../assets/currencies/currencies').Currency]: {
 *     [conversionCurrency in import('../assets/currencies/currencies').FiatCurrency]: string
 *   }
 * }} Rates
 */

/**
 * @returns {Promise<Rates>}
 */
export async function getRates() {
  const { data } = await axios.get(`https://api.paybitx.com/exchange/rates`);

  return data;
}

/**
 * @param {{enabled: boolean}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<Rates, Error>}
 **/
export function useRatesQuery(options) {
  return useQuery(["rates"], getRates, { staleTime: 30_0000, ...options });
}
