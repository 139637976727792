import React, { useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import * as storage from "../providers/storage";
import Logo from "../assets/pictures/logo.png";

import { Button } from "../components/UI/Buttons";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import useThemeVariables from "hooks/useThemeVariables";

const Header = () => {
  const navigate = useNavigate();
  const [yPos, setYPos] = useState(0);
  const { isDarkTheme } = useThemeVariables();
  const scrollFunc = () => {
    const y = window.scrollY;
    setYPos(y);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollFunc);

    return () => window.removeEventListener("scroll", scrollFunc);
  });

  const handleLogout = () => {
    storage.clear();
    navigate("/");
  };

  const handleTheme = () => {
    localStorage.getItem("theme") === "light"
      ? localStorage.setItem("theme", "dark")
      : localStorage.setItem("theme", "light");
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <div className={yPos >= 1 ? "header header-white" : "header"}>
      <div className="header__logo">
        <img src={Logo} alt="header-logo" />
      </div>
      <div className="header__actions">
        <div className="theme_toggler" onClick={handleTheme}>
          {isDarkTheme ? (
            <RiSunFill className="icon sun" size="2rem" />
          ) : (
            <RiMoonFill className="icon moon" size="2rem" />
          )}
        </div>
        <Button text="Log Out" click={handleLogout} />
      </div>
    </div>
  );
};

export default Header;
