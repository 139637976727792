import { takeLatest, call, put, all } from "redux-saga/effects";
import axios from "axios";
import * as storage from "../../providers/storage";

import {
  FETCH_USERS_START,
  UPDATE_USER_WITHDRAWAL_LIMIT_START,
} from "./users.types";

import {
  fetchUsersFail,
  fetchUsersSuccess,
  fetchUserSuccess,
  fetchUserFail,
  updateUserWithdrawalLimitSuccess,
  updateUserWithdrawalLimitFail,
} from "./users.actions";

export function* fetchUsersAsync() {
  const token = storage.get("token");

  try {
    const { data } = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URI}manager/user`,
      headers: {
        Authorization: token,
      },
    });

    yield put(fetchUsersSuccess(data.data.data));
  } catch (error) {
    yield put(fetchUsersFail(error));
  }
}

export function* fetchUserAsync({ payload: id }) {
  const token = storage.get("token");

  try {
    const { data } = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URI}manager/user/${id}`,
      headers: {
        Authorization: token,
      },
    });

    if (data) {
      yield put(fetchUserSuccess(data.data.data));
    } else {
      yield put(fetchUserFail("User Not Found"));
    }
  } catch (error) {
    yield put(fetchUserFail(error));
  }
}

export function* updateUserWithdrawalLimitAsync({payload: {amount, userId}}) {
  const token = storage.get("token");

  try {
    const { data } = yield axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URI}manager/user/withdrawal-limit`,
      headers: {
        Authorization: token,
      },
      data: {
        amount,
        userId
      },
    });

    if (data) {
      yield put(updateUserWithdrawalLimitSuccess(data.data.amount));
    } else {
      yield put(updateUserWithdrawalLimitFail("Limit could not be updated"));
    }
  } catch (error) {
    yield put(fetchUserFail(error));
  }
}

export function* fetchUsers() {
  yield takeLatest(FETCH_USERS_START, fetchUsersAsync);
}

export function* fetchUser() {
  yield takeLatest("FETCH_USER_START", fetchUserAsync);
}

export function* updateUserWithdrawalLimit() {
  yield takeLatest(
    UPDATE_USER_WITHDRAWAL_LIMIT_START,
    updateUserWithdrawalLimitAsync
  );
}

export function* usersSagas() {
  yield all([
    call(fetchUsers),
    call(fetchUser),
    call(updateUserWithdrawalLimit),
  ]);
}
