import { takeLatest, call, put, all } from "redux-saga/effects";
import axios from "axios";
import * as storage from "../../providers/storage";

import { FETCH_TRANSACTIONS_START } from "./transactions.types";

import {
  fetchTransactionsFail,
  fetchTransactionsSuccess,
} from "./transactions.actions";

export function* fetchTransactionsAsync({ payload }) {
  const token = storage.get("token");

  try {
    const { data } = yield axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URI}manager/transactions`,
      data: payload,
      headers: {
        Authorization: token,
      },
    });

    yield put(fetchTransactionsSuccess(data.data));
  } catch (error) {
    yield put(fetchTransactionsFail(error));
  }
}

export function* fetchTransactions() {
  yield takeLatest(FETCH_TRANSACTIONS_START, fetchTransactionsAsync);
}

export function* transactionsSagas() {
  yield all([call(fetchTransactions)]);
}
