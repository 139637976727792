export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";

export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const UPDATE_USER_WITHDRAWAL_LIMIT_START =
  "UPDATE_USER_WITHDRAWAL_LIMIT_START";
export const UPDATE_USER_WITHDRAWAL_LIMIT_SUCCESS =
  "UPDATE_USER_WITHDRAWAL_LIMIT_SUCCESS";
export const UPDATE_USER_WITHDRAWAL_LIMIT_FAIL =
  "UPDATE_USER_WITHDRAWAL_LIMIT_FAIL";
