import React from "react";

function TableCurrency({ currency, children }) {
  return <div>{Number(children).toFixed(CurrencyDecimals[`${currency}`])}</div>;
}

const CurrencyDecimals = {
  btc: 5,
  eth: 4,
  xrp: 0,
  usdt: 0,
  ltc: 4,
  xlm: 0,
  bch: 4,
  trx: 0,
  uni: 0,
  usdc: 0,
  busd: 0,
  bnb: 4,
  reef: 0,
  shib: 0,
  doge: 0,
  btt: 0,
  usd: 2,
  eur: 2,
  gel: 2,
  rub: 2,
  ton: 4,
};
export default TableCurrency;
