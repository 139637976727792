import React, { useEffect, useState } from "react";
import "./GeneralBalance.css";
import { connect } from "react-redux";
import { useTable } from "react-table";
import { fetchTotalBalanceStart } from "../../redux/balance/balance.actions";
import icons from "../../assets/currencies";
import TableCurrency from "components/Currency/TableCurrency.component";
import { ImSigma } from "react-icons/im";
import Loader from "components/UI/Loader";

// Temporary util
const adjustToTable = (obj) => {
  const data = [];
  for (const key in obj) {
    data.push(obj[key]);
  }

  return data;
};

const GeneralBalance = ({ fetchTotalBalanceStart, totalBalance, loading }) => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    fetchTotalBalanceStart();
  }, [fetchTotalBalanceStart]);

  useEffect(() => {
    if (Object.keys(totalBalance).length) {
      let res = adjustToTable(totalBalance);
      res = res.sort((a, b) => b.total_confirmed - a.total_confirmed);
      setdata(res);
    }
  }, [totalBalance]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Currency",
        accessor: "currency",
        Cell: (c) => {
          const coinName = c.row.original.currency;
          return (
            <div className="currency">
              {coinName === "SUM (USD)" ? (
                <ImSigma className="sum-icon" />
              ) : (
                <img src={icons[coinName]} alt="coin name" />
              )}
              {coinName}
            </div>
          );
        },
      },
      {
        Header: "	Wallet Balance",
        accessor: "total",
      },
      {
        Header: "PBX Confirmed Balance",
        accessor: "total_confirmed",
      },
      {
        Header: "PBX Unconfirmed Deposit",
        accessor: "total_unconfirmed_deposit",
      },
      {
        Header: "PBX Unconfirmed Withdrawal",
        accessor: "total_unconfirmed_withdrawal",
      },
      {
        Header: "PBX Exchange Totals",
        accessor: "exchange_totals",
      },
      {
        Header: "PBX Address totals",
        accessor: "address_totals",
      },
      {
        Header: "Accumulated Deposit",
        accessor: "total_accumulated",
      },
      {
        Header: "Net Gain",
        accessor: "netGain",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (loading) return <Loader />;
  return (
    <div className="general-balance page">
      {/* Start Table */}
      <table {...getTableProps()} className="users-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  backgroundColor:
                    row.id % 2 === 0
                      ? "rgba(25, 28, 29, 0.5)"
                      : "rgba(224, 227, 227, 0.02)",
                }}
              >
                {row.cells.map((cell) => {
                  return cell.column.Header === "Currency" ? (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ) : (
                    <td {...cell.getCellProps()}>
                      {
                        <TableCurrency currency={cell.row.values.currency}>
                          {!cell.value ? 0 : cell.value}
                        </TableCurrency>
                      }
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* End Table */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  totalBalance: state.balance.totalBalance,
  loading: state.balance.loadingTotalBalance,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTotalBalanceStart: () => dispatch(fetchTotalBalanceStart()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(GeneralBalance);
