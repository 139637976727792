// @ts-check
import { useNumberFormatter } from "@react-aria/i18n";
import CurrencyFormats from "assets/data/currency-formats";
import { useLocale } from "context/LanguageContext";
import * as React from "react";

const defaultFormatOptions = {
  style: "decimal",
  minimumFractionDigits: 5,
};

/**
 * @param {string} currency
 * @returns {typeof CurrencyFormats[keyof CurrencyFormats] | typeof defaultFormatOptions }
 */
function getFormatOptions(currency) {
  const formatOptions = CurrencyFormats[currency];
  if (formatOptions) {
    return formatOptions;
  }
  console.warn(
    `Weren't able to fetch format options for ${currency}. Using default options instead`
  );
  return defaultFormatOptions;
}

/**
 *
 * @typedef {object} NumberToCurrencyProps
 * @property {number} children
 * @property {string} currency
 *
 * @typedef CurrencyProps
 * @property {React.ReactNode} children
 * @property {string} currency
 */

const NumberToCurrency = React.memo(
  /** @param {NumberToCurrencyProps}  props*/
  ({ children, currency }) => {
    let formattedValue;

    const formatOptions = getFormatOptions(currency);

    const formatter = useNumberFormatter({
      ...formatOptions,
      currency: formatOptions.style === "currency" ? currency : undefined,
    });

    const { trueLocale } = useLocale();

    if (formatOptions.style === "decimal") {
      // a simple test to get where Intl.Numberformatter puts the currency symbol
      const currencyIndex = Intl.NumberFormat(trueLocale, {
        style: "currency",
        currency: "usd",
      })
        .formatToParts(+children)
        .findIndex(({ type }) => type === "currency");

      // insert the unsupported currency symbol where the supported currency symbol would be
      let formattedDecimal = formatter.formatToParts(children);

      formattedDecimal.splice(currencyIndex, 0, {
        value: `\xa0${currency.toUpperCase()}\xa0`,
        type: "currency",
      });
      formattedValue = formattedDecimal
        .map(({ value }) => value)
        .join("")
        .trim();
    } else {
      formattedValue = formatter.format(children);
    }

    return <>{formattedValue}</>;
  }
);

/** @param {CurrencyProps} props */
export default function Currency({ children, currency }) {
  const sentence = React.Children.map(children, (child) => {
    if (typeof child === "number") {
      return <NumberToCurrency currency={currency}>{child}</NumberToCurrency>;
    }
    return child;
  });
  return <>{sentence}</>;
}
