import {
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  UPDATE_USER_WITHDRAWAL_LIMIT_START,
  UPDATE_USER_WITHDRAWAL_LIMIT_SUCCESS,
  UPDATE_USER_WITHDRAWAL_LIMIT_FAIL,
} from "./users.types";

// Users acions
export const fetchUsersStart = () => ({
  type: FETCH_USERS_START,
});

export const fetchUsersSuccess = (payload) => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersFail = (payload) => ({
  type: FETCH_USERS_FAIL,
  payload,
});

//User actions

export const fetchUserStart = (id) => ({
  type: FETCH_USER_START,
  payload: id,
});

export const fetchUserSuccess = (payload) => ({
  type: FETCH_USER_SUCCESS,
  payload,
});

export const fetchUserFail = (payload) => ({
  type: FETCH_USER_FAIL,
  payload,
});

export const updateUserWithdrawalLimitStart = (payload) => ({
  type: UPDATE_USER_WITHDRAWAL_LIMIT_START,
  payload,
});

export const updateUserWithdrawalLimitSuccess = (payload) => ({
  type: UPDATE_USER_WITHDRAWAL_LIMIT_SUCCESS,
  payload,
});

export const updateUserWithdrawalLimitFail = (payload) => ({
  type: UPDATE_USER_WITHDRAWAL_LIMIT_FAIL,
  payload,
});
