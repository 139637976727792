import {
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAIL,
  UPDATE_TX
} from './transactions.types';

// Users acions
export const fetchTransactionsStart = (payload) => ({
  type: FETCH_TRANSACTIONS_START,
  payload
});

export const fetchTransactionsSuccess = (payload) => ({
  type: FETCH_TRANSACTIONS_SUCCESS,
  payload,
});

export const fetchTransactionsFail = (payload) => ({
  type: FETCH_TRANSACTIONS_FAIL,
  payload,
});

export const updateTx = (payload) => ({
  type: UPDATE_TX,
  payload,
});