import {
    FETCH_DASHBOARD_DATA_START,
    FETCH_DASHBOARD_DATA_SUCCESS,
    FETCH_DASHBOARD_DATA_FAIL,
  } from './dashboard.types';
  
  const INIT_STATE = {
    dashboardData: {},
    loadingdashboardData: false,
    dashboardDataError: null,
  };
  
  const dashboardReducer = (state = INIT_STATE, { type, payload }) => {
    switch (type) {
      case FETCH_DASHBOARD_DATA_START:
        return {
          ...state,
          loadingdashboardData: true,
        };
  
      case FETCH_DASHBOARD_DATA_SUCCESS:
        return {
          ...state,
          loadingdashboardData: false,
          dashboardData: payload,
        };
  
      case FETCH_DASHBOARD_DATA_FAIL:
        return {
          ...state,
          loadingdashboardData: false,
          dashboardDataError: payload,
        };
      default:
        return state;
    }
  };
  
  export default dashboardReducer;
  