import React, { useState } from "react";
import "./LoginForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as storage from "../../providers/storage";

import Input from "../../components/UI/Input";
import { SubmitBtn } from "../../components/UI/Buttons";

const LoginForm = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const { email, password } = credentials;
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URI}v1/auth`,
        data: {
          email: email,
          password: password,
        },
      });

      if (data?.token) storage.set("token", data?.token);
      navigate("/dashboard");
    } catch (error) {
      console.log("AUTH_ERR: ", error);
    }
  };

  const handleChange = ({ target: { name, value } }) => {
    const newCreds = { ...credentials };
    newCreds[name] = value;
    setCredentials(newCreds);
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <h1 className="login-form__title">Sign In</h1>

      <div className="login-form__inputs">
        <Input
          label="Enter your E-mail"
          placeholder="Email*"
          type="text"
          name="email"
          value={email}
          onChange={handleChange}
        />

        <Input
          label="Password"
          placeholder="Password*"
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
        />

        <SubmitBtn
          value="Log In"
          disabled={!email.length || !password.length}
        />
      </div>
    </form>
  );
};

export default LoginForm;
