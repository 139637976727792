import {
  FETCH_TRANSACTIONS_START,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAIL,
  UPDATE_TX,
} from "./transactions.types";

const INIT_STATE = {
  transactions: [],
  pages: 0,
  loadingTxs: false,
  txError: null,
};

const transactionsReducer = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    // Users cases
    case FETCH_TRANSACTIONS_START:
      return {
        ...state,
        loadingTxs: true,
      };

    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingTxs: false,
        transactions: payload.items,
        pages: payload.pages,
      };

    case FETCH_TRANSACTIONS_FAIL:
      return {
        ...state,
        loadingTxs: false,
        txError: payload,
      };
    case UPDATE_TX:
      const updatedTxs = state.transactions.map((t) => {
        if (t.id === payload.data.id && t.type === "withdrawal") {
          t.is_approved = payload.data.is_approved;
        }
        return t;
      });
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
