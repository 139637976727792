import {
  FETCH_EXCHANGE_TOTALS_START,
  FETCH_EXCHANGE_TOTALS_SUCCESS,
  FETCH_EXCHANGE_TOTALS_FAIL,
} from "./exchange.types";

// Total exchange actions
export const fetchExchangeTotalsStart = () => ({
  type: FETCH_EXCHANGE_TOTALS_START,
});

export const fetchExchangeTotalsSuccess = (payload) => ({
  type: FETCH_EXCHANGE_TOTALS_SUCCESS,
  payload,
});

export const fetchExchangeTotalsFail = (payload) => ({
  type: FETCH_EXCHANGE_TOTALS_FAIL,
  payload,
});
