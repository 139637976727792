import { all, call } from "redux-saga/effects";

import { balanceSagas } from "./balance/balance.sagas";
import { exchangeSagas } from "./exchangeTotals/exchange.sagas";
import { dashboardSagas } from "./dashboard/dashboard.sagas";
import { usersSagas } from "./users/users.sagas";
import { transactionsSagas } from "./transactions/transactions.sagas";

export default function* rootSaga() {
  yield all([
    call(balanceSagas),
    call(exchangeSagas),
    call(dashboardSagas),
    call(usersSagas),
    call(transactionsSagas),
  ]);
}
