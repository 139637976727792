import React from "react";
import "./CoinStatus.css";
import CoinStatusCard from "./coinStatus/CoinStatusCard";
import Loader from "../components/UI/Loader";
import { useCoinStatusQuery } from "../queries/coinStatus";

const CoinStatus = () => {
  const { data } = useCoinStatusQuery();

  return (
    <div className="page">
      <div className="coin-status">
        {!data && <Loader />}

        {data &&
          data.data.length > 0 &&
          data.data.map((coin) => <CoinStatusCard coin={coin} />)}
      </div>
    </div>
  );
};

export default CoinStatus;
