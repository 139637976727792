// @ts-check

import * as React from "react";
import { useHover, usePress } from "react-aria";
import { isFragment } from "react-is";
import {
  ElevationProvider,
  useElevation,
} from "../../../../context/ElevationContext";
import classNames from "classnames";
import styles from "./WithElevation.module.css";
import { mergeProps } from "@react-aria/utils";

const clamp5 = (val) => Math.max(Math.min(5, val), 0);
const clamp24 = (val) => Math.max(Math.min(24, val), 0);

/**
 * adds className that changes background-image when elevation
 *
 * @param {object} props
 * @param {any} props.children
 * @param {"tint" | "shadow" | "both"} [props.type]
 * @param {number} [props.base] - base elevation
 * @param {number} [props.whileHover] hover elevation
 * @param {number} [props.whilePress] press elevation
 * @param {boolean} [props.isolate] when true, wont aggregate tint colors
 */
function WithElevation({
  children,
  type = "tint",
  base = 0,
  whileHover,
  whilePress,
  isolate,
}) {
  if (isFragment(children)) {
    throw new Error(
      "`WithElevation` expects a single child that is also not a `React.Fragment`"
    );
  }

  const { isHovered, hoverProps } = useHover({});
  const { isPressed, pressProps } = usePress({});

  const parentElevation = useElevation();
  const startElevation = isolate ? 0 : parentElevation;

  const relativeElevation = isPressed
    ? whilePress
    : isHovered
    ? whileHover
    : base;

  const getChildProps = () => {
    const tintLevel = startElevation + relativeElevation;
    const style = {
      "--tint-color": `var(--color-surface-tint-at-${clamp5(tintLevel)})`,
      "--shadow": `var(--shadow-${clamp24(relativeElevation)})`,
    };

    const className = classNames(styles.withElevation, styles[type]);

    // don't pass hoverProps and pressProps to the child element if it does not require it.
    // this makes it not have unintentional side effects like making text unselectable.
    const _hoverProps = whileHover === undefined ? {} : hoverProps;
    const _pressProps = whilePress === undefined ? {} : pressProps;

    return mergeProps({ className, style }, _hoverProps, _pressProps);
  };

  let elevatedChildren;
  if (typeof children === "function") {
    // provide props as arguments to children function
    elevatedChildren = children(getChildProps());
  } else {
    // automatically merge and add props to children
    elevatedChildren = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const childProps = getChildProps();
        const props = mergeProps(childProps, child.props, {
          style: { ...childProps.style, ...child.props.style },
        });
        return React.cloneElement(child, props);
      }
    });
  }

  return (
    // elevation context is changed so that deep nested `WithElevation` 's can use (and add on) parent elevation
    <ElevationProvider elevation={startElevation + relativeElevation}>
      {elevatedChildren}
    </ElevationProvider>
  );
}

export default WithElevation;
