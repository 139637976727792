// @ts-check
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import * as storage from "../providers/storage";

/**
 * @typedef CoinStatus
 *
 * @property {string} status
 * @property {string} message
 * @property {Array[object]} data
 */

/**
 * @returns {Promise<CoinStatus>}
 */

export async function getCoinStatus() {
  const { data } = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URI}manager/coin/status`,
    headers: {
      Authorization: storage.get("token"),
    },
  });

  return data;
}

/**
 * @param {{enabled: boolean}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<CoinStatus, Error>}
 **/

export function useCoinStatusQuery(options) {
  return useQuery(["coinStatus"], getCoinStatus, {
    staleTime: 30000,
    ...options,
  });
}
