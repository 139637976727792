/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from "react";
import { useListBox, useOption } from "react-aria";
import { RiCheckLine } from "react-icons/ri";
import "./Styles.css";

export function ListBox(props) {
  let ref = React.useRef(null);
  let { listBoxRef = ref, state } = props;
  let { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ul {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
}

function Option({ item, state }) {
  let ref = React.useRef(null);
  let { optionProps, isSelected } = useOption(
    {
      key: item.key,
      // Allow the user to click, drag, and release to select an item,
      // except if the item is already selected. This avoids the menu
      // closing immediately after opening since the selected option
      // is positioned directly over the button.
      shouldSelectOnPressUp: !state.selectionManager.isSelected(item.key),
    },
    state,
    ref
  );

  return (
    <li
      style={
        optionProps["data-key"] === "approve"
          ? { backgroundColor: "green" }
          : { backgroundColor: "red" }
      }
      {...optionProps}
      ref={ref}
    >
      <span>{item.rendered}</span>
    </li>
  );
}
