import { Expand } from "components/Animations/Expand.component";
import { AnimatePresence } from "framer-motion";
import * as React from "react";
import cx from "classnames";
import styles from "./ValidationAndHints.module.css";

/**
 * @typedef ValidationAndHintsProps
 * @property {{errorMessage?: string| string[], description?: string| string[]}} [props.classNames]
 * @property {"valid" | "invalid"| "null"} [props.validationState]
 * @property {boolean} [props.isDisabled]
 * @property {React.ReactNode} [props.description]
 * @property {React.ReactNode} [props.errorMessage]
 * @property {React.HTMLAttributes<HTMLElement>} props.descriptionProps
 * @property {React.HTMLAttributes<HTMLElement>} props.errorMessageProps
 *
 * @param {ValidationAndHintsProps} props
 */

export default function ValidationAndHints({
  validationState,
  errorMessage,
  errorMessageProps,
  description,
  descriptionProps,
  isDisabled,
  classNames = {},
}) {
  return (
    <>
      <AnimatePresence>
        {validationState === "invalid" && errorMessage && !isDisabled && (
          <Expand
            elementType="div"
            {...errorMessageProps}
            className={cx(classNames.errorMessage, styles.errorMessage)}
          >
            {errorMessage}
          </Expand>
        )}
      </AnimatePresence>
      {description && (
        <div
          {...descriptionProps}
          className={cx(classNames.description, styles.description)}
        >
          {description}
        </div>
      )}
    </>
  );
}
