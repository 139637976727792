// @ts-check
import * as React from "react";
import { useButton } from "@react-aria/button";
import { mergeProps } from "@react-aria/utils";
import { motion } from "framer-motion";
import cx from "classnames";
import { FocusRing } from "@react-aria/focus";
import WithElevation from "./Elevation/WithElevation.component";
import useDefaultRef from "hooks/useDefaultRef";
import styles from "./ButtonBase.module.css";

/**
 * @typedef ClassNames
 * @property {string} [focusRing]
 * @property {string} [button]
 *
 */

/**
 * @typedef ButtonBaseLocalProps
 * @property {any} children
 * @property {ClassNames} [classNames]
 * @property {import("framer-motion").VariantLabels | import("framer-motion").TargetAndTransition} [whileTap]
 * @property {import("framer-motion").VariantLabels | import("framer-motion").TargetAndTransition} [whileHover]
 * @property {import("framer-motion").VariantLabels | import("framer-motion").TargetAndTransition} [whileFocus]
 * @property {import("framer-motion").Variants} [variants]
 * @property {import("framer-motion").MotionStyle} [style]
 * @property {any} [initial] framer-motion's initial prop
 * @property {number} [baseElevation]
 * @property {number} [hoverElevation]
 * @property {number} [pressElevation]
 * @property {"tint" | "shadow" | "both"} [elevationType]
 * @property {boolean} [isolateElevation]
 */

/**
 * @template {"button" | "a"} T
 * @typedef {import("@react-types/button").AriaButtonProps<T>} AriaButtonProps
 */

/**
 * @template {"button" | "a"} T
 * @param {AriaButtonProps<T> & ButtonBaseLocalProps} props
 */
function ButtonBase(
  {
    classNames = {},
    children,
    whileTap,
    whileHover,
    whileFocus,
    variants,
    initial,
    style,
    baseElevation,
    hoverElevation,
    pressElevation,
    elevationType,
    isolateElevation,
    ...props
  },
  parentRef
) {
  const ref = useDefaultRef(parentRef);

  const { ...ariaInteractions } = props;

  const { buttonProps, isPressed } = useButton(
    mergeProps(ariaInteractions, props),
    ref
  );

  // Support for custom elements like RouteLink is currently unimplemented
  /** @type {import("framer-motion").ForwardRefComponent<HTMLButtonElement | HTMLAnchorElement , import("framer-motion").HTMLMotionProps<"button" | "a">>} */
  let ButtonElement = motion.button;
  if (props.elementType === "a") {
    ButtonElement = motion.a;
  }

  return (
    <FocusRing within focusRingClass="--isKeyboardFocused">
      <div className={cx(classNames.focusRing)}>
        <WithElevation
          base={baseElevation}
          whileHover={hoverElevation}
          whilePress={pressElevation}
          type={elevationType}
          isolate={isolateElevation}
        >
          {/* @ts-ignore framer-motion changed some default html element props like onAnimationStart, but we aren't using them */}
          <ButtonElement
            {...buttonProps}
            whileTap={!props.isDisabled && whileTap}
            whileHover={!props.isDisabled && whileHover}
            whileFocus={!props.isDisabled && whileFocus}
            variants={variants}
            style={style}
            initial={initial}
            className={cx(classNames.button, styles.button, {
              "--is-pressed": isPressed,
            })}
            ref={ref}
          >
            {children}
          </ButtonElement>
        </WithElevation>
      </div>
    </FocusRing>
  );
}

export default React.forwardRef(ButtonBase);
