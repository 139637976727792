import React, { useState } from "react";
import "./Transactions-filter.css";
import { AiOutlineDown } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaSistrix } from "react-icons/fa";
import { AiFillFilter } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { cryptoCurrencies } from "../../assets/currencies/currencies.ts";

const coins = cryptoCurrencies;

const TransactionsFilter = ({
  handleTypeChange,
  type,
  handleCurrChange,
  currActive,
  handleChangeFrom,
  from,
  handleChangeTo,
  to,
  handleUserId,
  userId,
  handleTxId,
  txId,
  handleAddress,
  address,
}) => {
  const [showCurr, setShowCurr] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  return (
    <>
      <div className="op-filter-wrapper">
        <span className="resp-filter-btn" onClick={() => setShowFilter(true)}>
          <span>Filter</span>
          <span>
            <AiFillFilter />
          </span>
        </span>
        <div className="op-filter-main">
          <span
            className={
              type === "deposit"
                ? "op-filter-main__btn btn-active"
                : "op-filter-main__btn"
            }
            onClick={() => handleTypeChange("deposit")}
          >
            Deposit
          </span>
          <span
            className={
              type === "withdrawal"
                ? "op-filter-main__btn btn-active"
                : "op-filter-main__btn"
            }
            onClick={() => handleTypeChange("withdrawal")}
          >
            Withdrawal
          </span>
          <span
            className={
              type === "invoice"
                ? "op-filter-main__btn btn-active"
                : "op-filter-main__btn"
            }
            onClick={() => handleTypeChange("invoice")}
          >
            Invoice
          </span>

          <div className="op-filter-main__dropdown">
            <div
              className={
                currActive
                  ? "op-filter-main__btn btn-active"
                  : "op-filter-main__btn"
              }
              onClick={() => setShowCurr((prevState) => !prevState)}
            >
              Currency
              <AiOutlineDown />
            </div>
            <div
              className={
                showCurr ? "op-filter-dd show-filter-dd" : "op-filter-dd"
              }
            >
              <ul>
                {coins.map((coin) => (
                  <li key={coin}>
                    <label htmlFor={coin + "id"}>{coin}</label>
                    <input
                      type="checkbox"
                      id={coin + "id"}
                      value={coin}
                      onClick={(e) => handleCurrChange(e)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="op-filter__date">
            <span>Date</span>
            <div>
              <DatePicker
                popperPlacement="top-start"
                dateFormat="yyyy/MM/dd"
                placeholderText="From"
                wrapperClassName="datePicker"
                selected={from}
                onChange={(date) => handleChangeFrom(date)}
              />
            </div>

            <div>
              <DatePicker
                popperPlacement="top-start"
                dateFormat="yyyy/MM/dd"
                placeholderText="To"
                wrapperClassName="datePicker"
                selected={to}
                onChange={(date) => handleChangeTo(date)}
              />
            </div>
          </div>
        </div>

        <div className="op-filter-search">
          <div>
            <FaSistrix />
            <input
              type="text"
              placeholder="Search User ID"
              value={userId}
              onChange={handleUserId}
            />
          </div>
          <div>
            <FaSistrix />
            <input
              type="text"
              placeholder="Search transaction ID"
              value={txId}
              onChange={handleTxId}
            />
          </div>
          <div>
            <FaSistrix />
            <input
              type="text"
              placeholder="Search transaction address"
              value={address}
              onChange={handleAddress}
            />
          </div>
        </div>

        <div
          className={
            showFilter ? "op-filter-resp-show op-filter-resp" : "op-filter-resp"
          }
        >
          <div className="op-filter-main-resp">
            <span>
              <MdClose
                className="close-icon"
                size="2rem"
                onClick={() => setShowFilter(false)}
              />
            </span>
            <span
              className={
                type === "deposit"
                  ? "op-filter-main__btn btn-active"
                  : "op-filter-main__btn"
              }
              onClick={() => handleTypeChange("deposit")}
            >
              <span>Deposit</span>
            </span>
            <span
              className={
                type === "withdrawal"
                  ? "op-filter-main__btn btn-active"
                  : "op-filter-main__btn"
              }
              onClick={() => handleTypeChange("withdrawal")}
            >
              Withdrawal
            </span>
            <span
              className={
                type === "invoice"
                  ? "op-filter-main__btn btn-active"
                  : "op-filter-main__btn"
              }
              onClick={() => handleTypeChange("invoice")}
            >
              Invoice
            </span>

            <div className="op-filter-main__dropdown">
              <div
                className={
                  currActive
                    ? "op-filter-main__btn btn-active"
                    : "op-filter-main__btn"
                }
                onClick={() => setShowCurr((prevState) => !prevState)}
              >
                Currency
                <AiOutlineDown />
              </div>
              <div
                className={
                  showCurr ? "op-filter-dd show-filter-dd" : "op-filter-dd"
                }
              >
                <ul>
                  {coins.map((coin) => (
                    <li key={coin}>
                      <label htmlFor={coin + "id"}>{coin}</label>
                      <input
                        type="checkbox"
                        id={coin + "id"}
                        value={coin}
                        onClick={(e) => handleCurrChange(e)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="op-filter__date">
              <span>Date</span>
              <div>
                <DatePicker
                  popperPlacement="top-start"
                  dateFormat="yyyy/MM/dd"
                  placeholderText="From"
                  wrapperClassName="datePicker"
                  selected={from}
                  onChange={(date) => handleChangeFrom(date)}
                />
              </div>

              <div>
                <DatePicker
                  popperPlacement="top-start"
                  dateFormat="yyyy/MM/dd"
                  placeholderText="To"
                  wrapperClassName="datePicker"
                  selected={to}
                  onChange={(date) => handleChangeTo(date)}
                />
              </div>
            </div>
          </div>

          <div className="op-filter-search-resp">
            <div>
              <FaSistrix />
              <input
                type="text"
                placeholder="Search User ID"
                value={userId}
                onChange={handleUserId}
              />
            </div>
            <div>
              <FaSistrix />
              <input
                type="text"
                placeholder="Search transaction ID"
                value={txId}
                onChange={handleTxId}
              />
            </div>
            <div>
              <FaSistrix />
              <input
                type="text"
                placeholder="Search transaction address"
                value={address}
                onChange={handleAddress}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsFilter;
