// @ts-check
import * as React from "react";
import { mergeClassNames } from "../../utils/classNames";
import styles from "./ListBox.module.css";
import ListBoxBase from "./ListBoxBase.component";

/**
 * @template T
 * @param {import("./ListBoxBase.component").ListBoxBaseProps<T> & { isVariant?: boolean }} props
 **/
function ListBoxWithRef(props, ref) {
  const classNames = mergeClassNames(props.classNames || {}, styles, {
    label: props.isVariant ? "--isVariant" : "",
    listbox: props.isVariant ? "--isVariant" : "",
  });

  return <ListBoxBase {...props} classNames={classNames} ref={ref} />;
}

const ListBox = React.forwardRef(ListBoxWithRef);
export default ListBox;
