import {
  FETCH_TOTAL_BALANCE_START,
  FETCH_TOTAL_BALANCE_SUCCESS,
  FETCH_TOTAL_BALANCE_FAIL,
} from './balance.types';

const INIT_STATE = {
  totalBalance: {},
  loadingTotalBalance: false,
  totalBalanceError: null,
};

const balanceReducer = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_TOTAL_BALANCE_START:
      return {
        ...state,
        loadingTotalBalance: true,
      };

    case FETCH_TOTAL_BALANCE_SUCCESS:
      return {
        ...state,
        loadingTotalBalance: false,
        totalBalance: payload,
      };

    case FETCH_TOTAL_BALANCE_FAIL:
      return {
        ...state,
        loadingTotalBalance: false,
        totalBalanceError: payload,
      };
    default:
      return state;
  }
};

export default balanceReducer;
