import {
  FETCH_USERS_START,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  UPDATE_USER_WITHDRAWAL_LIMIT_START,
  UPDATE_USER_WITHDRAWAL_LIMIT_SUCCESS,
  UPDATE_USER_WITHDRAWAL_LIMIT_FAIL,
} from "./users.types";

const INIT_STATE = {
  users: [],
  user: {},
  loadingUsers: false,
  loadingUser: false,
  usersError: null,
  userError: null,
};

const usersReducer = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    // Users cases
    case FETCH_USERS_START:
      return {
        ...state,
        loadingUsers: true,
      };

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loadingUsers: false,
        users: payload,
      };

    case FETCH_USERS_FAIL:
      return {
        ...state,
        loadingUsers: false,
        usersError: payload,
      };

    // Single User Cases
    case FETCH_USER_START:
      return {
        ...state,
        loadingUser: true,
      };

    case FETCH_USER_SUCCESS:
      const {
        id,
        PersonalAccount,
        phone_number,
        email,
        is_admin,
        withdrawal_limit,
      } = payload;

      const _user = {
        id,
        email,
        is_admin,
        country: PersonalAccount.Country.name,
        first_name: PersonalAccount.first_name,
        last_name: PersonalAccount.last_name,
        phone_number: phone_number || "Not Set",
        withdrawal_limit: withdrawal_limit,
      };

      return {
        ...state,
        loadingUser: false,
        user: _user,
      };

    case FETCH_USER_FAIL:
      return {
        ...state,
        loadingUser: false,
        userError: payload,
      };

    case UPDATE_USER_WITHDRAWAL_LIMIT_START:
      return {
        ...state,
        loadingUsers: true,
      };

    case UPDATE_USER_WITHDRAWAL_LIMIT_SUCCESS:
      const updatedUser = {
        ...state.user,
        withdrawal_limit: payload,
      };

      return {
        ...state,
        loadingUsers: false,
        user: updatedUser,
      };

    case UPDATE_USER_WITHDRAWAL_LIMIT_FAIL:
      return {
        ...state,
        loadingUsers: false,
        usersError: payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
