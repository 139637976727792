import React from "react";
import "./Settings.css";
import {
  RiAddCircleLine,
  RiSave3Line,
  RiDeleteBinLine,
  RiCloseLine,
} from "react-icons/ri";
import { useSettingsQuery } from "../queries/settings";
import Loader from "../components/UI/Loader";
import axios from "axios";
import * as storage from "../providers/storage";
import toast from "react-hot-toast";

function Settings() {
  const { data, refetch } = useSettingsQuery();

  const [input, setInput] = React.useState("");
  const [addSettingName, setAddSettingName] = React.useState("");
  const [addSettingValue, setAddSettingValue] = React.useState("");
  const [serverError, setServerError] = React.useState(null);
  const [addSetting, setAddSetting] = React.useState(false);

  const token = storage.get("token");
  async function createSetting(settingName, settingValue) {
    try {
      const res = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URI}manager/config`,
        data: {
          setting_name: settingName,
          setting_value: settingValue,
        },
        headers: {
          authorization: token,
        },
      });
      toast.success("Setting created!");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  }

  async function updateSetting(settingName, settingValue) {
    try {
      const res = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_API_URI}manager/config`,
        data: {
          setting_name: settingName,
          setting_value: settingValue,
        },
        headers: {
          authorization: token,
        },
      });
      toast.success("Setting updated!");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(serverError);
    }
  }

  async function deleteSetting(settingName) {
    try {
      const res = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URI}manager/config`,
        data: {
          setting_name: settingName,
        },
        headers: {
          authorization: token,
        },
      });

      toast.success("Setting deleted!");
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
  }

  return (
    <div className="settingsContainer">
      <div className="headersSettings">
        <></>
        <div className="headerSettings">
          Setting
          <span
            onClick={() => setAddSetting(!addSetting)}
            style={{ marginLeft: "2rem" }}
          >
            <RiAddCircleLine
              size="2rem"
              style={{ color: "#58e070", cursor: "pointer" }}
            />
          </span>
        </div>
        <div className="headerSettings">Value</div>
      </div>

      <>
        {!data && <Loader />}
        {addSetting && (
          <div className="settingsTableAdd">
            <div className="settingNameAdd">
              Setting Name
              <input
                className="input"
                onChange={(e) => setAddSettingName(e.target.value)}
              ></input>
            </div>
            <div className="settingValueAdd">
              Setting Value
              <div className="inputContainer">
                <input
                  className="input"
                  onChange={(e) => setAddSettingValue(e.target.value)}
                ></input>
                <div className="updateButton">
                  <RiAddCircleLine
                    style={{ color: "#58e070", cursor: "pointer" }}
                    size="2rem"
                    onClick={async () => {
                      await createSetting(addSettingName, addSettingValue);
                      await refetch();
                    }}
                  />
                </div>
                <div className="removeButton">
                  <RiCloseLine
                    style={{ color: "#dd3730", cursor: "pointer" }}
                    size="2rem"
                    onClick={async () => {
                      await setAddSetting(false);
                      await refetch();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {data &&
          data.data
            .sort((a, b) => a.setting_name.localeCompare(b.setting_name))
            .map((setting) => (
              <div className="settingsTable">
                <div className="settingName"> {setting.setting_name}</div>
                <div className="settingValue">
                  <input
                    className="input"
                    defaultValue={setting.setting_value}
                    onChange={(e) => setInput(e.target.value)}
                  ></input>
                  <div className="updateButton">
                    <RiSave3Line
                      style={{ color: "#58e070", cursor: "pointer" }}
                      size="2rem"
                      onClick={async () => {
                        await updateSetting(setting.setting_name, input);
                        await refetch();
                      }}
                    />
                  </div>
                  <div className="removeButton">
                    <RiDeleteBinLine
                      style={{ color: "#dd3730", cursor: "pointer" }}
                      size="2rem"
                      onClick={async () => {
                        await deleteSetting(setting.setting_name);
                        await refetch();
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
      </>
    </div>
  );
}

export default Settings;
