import React, { useEffect, useState } from "react";
import "./ExchangeTotals.css";
import { connect } from "react-redux";
import { useTable } from "react-table";
import { fetchExchangeTotalsStart } from "../../redux/exchangeTotals/exchange.actions";
import icons from "../../assets/currencies";
import Currency from "components/Currency/Currency.component";
import TableCurrency from "components/Currency/TableCurrency.component";
import { ImSigma } from "react-icons/im";

// Temporary util
const adjustToTable = (obj) => {
  const data = [];
  for (const key in obj) {
    data.push(obj[key]);
  }

  return data;
};

const ExchangeTotals = ({ fetchExchangeTotalsStart, exchangeTotals }) => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    fetchExchangeTotalsStart();
  }, [fetchExchangeTotalsStart]);

  useEffect(() => {
    if (Object.keys(exchangeTotals).length) {
      let res = adjustToTable(exchangeTotals);
      setdata(res);
    }
  }, [exchangeTotals]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Currency",
        accessor: "currency",
        Cell: (c) => {
          const coinName = c.row.original.currency;

          return (
            <div className="currency">
              {coinName === "SUM" ? (
                <ImSigma className="sum-icon" />
              ) : (
                <img src={icons[coinName]} alt="coin name" />
              )}
              {coinName}
            </div>
          );
        },
      },
      {
        Header: "Total In (Before Fees)",
        accessor: "in_nominal",
      },
      {
        Header: "Total In (After Fees)",
        accessor: "in",
      },
      {
        Header: "Total Out",
        accessor: "out",
      },
      {
        Header: "Total Fees",
        accessor: "fees",
      },
      {
        Header: "Net Exchange",
        accessor: "exchange_totals",
      },
      {
        Header: "Net Exchange (USD)",
        accessor: "exchange_totals_usd",
      },
      {
        Header: "Net Fees (USD)",
        accessor: "exchange_fees_usd",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <div className="general-balance page">
      {/* Start Table */}
      <table {...getTableProps()} className="users-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  backgroundColor:
                    row.id % 2 === 0
                      ? "rgba(25, 28, 29, 0.5)"
                      : "rgba(224, 227, 227, 0.02)",
                }}
              >
                {row.cells.map((cell, i) => {
                  return cell.column.Header === "Currency" ? (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ) : (
                    <td {...cell.getCellProps()}>
                      {
                        <TableCurrency currency={cell.row.cells[0].value}>
                          {cell.value ? cell.value : console.log(cell)}
                        </TableCurrency>
                      }
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* End Table */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  exchangeTotals: state.exchange.exchangeTotals,
});

const mapDispatchToProps = (dispatch) => ({
  fetchExchangeTotalsStart: () => dispatch(fetchExchangeTotalsStart()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ExchangeTotals);
