import { takeLatest, call, put, all } from "redux-saga/effects";
import axios from "axios";
import * as storage from "../../providers/storage";

import { FETCH_EXCHANGE_TOTALS_START } from "./exchange.types";

import {
  fetchExchangeTotalsSuccess,
  fetchExchangeTotalsFail,
} from "./exchange.actions";

const ignore_currencies = [
  "zrx",
  "omg",
  "vet",
  "chz",
  "snx",
  "link",
  "mkr",
  "enj",
];

export function* fetchExchangeTotalsAsync() {
  const token = storage.get("token");

  try {
    const {
      data: {
        data: {
          exchangeInNominal,
          exchangeIn,
          exchangeOut,
          exchangeFees,
          exchangeTotals,
          exchangeFeesUsd,
          exchangeTotalsUsd,
        },
      },
    } = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URI}manager/statistics/exchange-totals`,
      headers: {
        Authorization: token,
      },
    });

    let sumTotals = 0;
    let sumFees = 0;
    Object.keys(exchangeTotalsUsd).forEach((coin) => {
      exchangeTotalsUsd[coin] = {
        in_nominal: exchangeInNominal[coin],
        in: exchangeIn[coin],
        out: exchangeOut[coin],
        fees: exchangeFees[coin],
        exchange_totals: exchangeTotals[coin],
        exchange_totals_usd: exchangeTotalsUsd[coin],
        exchange_fees_usd: exchangeFeesUsd[coin],
      };
      sumTotals =
        sumTotals + Number(exchangeTotalsUsd[coin].exchange_totals_usd);
      sumFees = sumFees + Number(exchangeFeesUsd[coin]);
      exchangeTotalsUsd["SUM"] = {
        in_nominal: 0,
        in: 0,
        out: 0,
        fees: 0,
        exchange_totals: 0,
        exchange_totals_usd: sumTotals,
        exchange_fees_usd: sumFees,
      };
    });

    for (let i = 0; i < ignore_currencies.length; i += 1) {
      let curr = ignore_currencies[i];
      delete exchangeTotals[curr];
      delete exchangeFees[curr];
      delete exchangeInNominal[curr];
      delete exchangeIn[curr];
      delete exchangeOut[curr];
      delete exchangeFeesUsd[curr];
      delete exchangeTotalsUsd[curr];
    }

    Object.keys(exchangeTotalsUsd).forEach((coin, i) => {
      if (!exchangeTotalsUsd[coin]) exchangeTotalsUsd[coin] = 0;
      exchangeTotalsUsd[coin].currency = coin;
    });

    yield put(fetchExchangeTotalsSuccess(exchangeTotalsUsd));
  } catch (error) {
    yield put(fetchExchangeTotalsFail(error));
  }
}

export function* fetchexchangeTotals() {
  yield takeLatest(FETCH_EXCHANGE_TOTALS_START, fetchExchangeTotalsAsync);
}

export function* exchangeSagas() {
  yield all([call(fetchexchangeTotals)]);
}
