const prefix = 'pbx_manager_';
export const set = (key, value) => {
  const _value = {
    key: value,
  };
  localStorage.setItem(prefix + key, JSON.stringify(_value));
};

export const get = (key) => {
  const item = localStorage.getItem(prefix + key);
  if (item) return JSON.parse(item).key;
  return null;
};

export const unset = (key) => {
  localStorage.removeItem(prefix + key);
};

export const clear = () => {
  let arr = [];

  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).substring(0, 12) === 'pbx_manager_') {
      arr.push(localStorage.key(i));
    }
  }

  // Iterate over arr and remove the items by key
  for (let i = 0; i < arr.length; i++) {
    localStorage.removeItem(arr[i]);
  }
};
