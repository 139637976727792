import React from 'react';
import './Input.css';

const Input = ({ name, label, value, onChange, ...otherProps }) => {
  return (
    <div className='input-wrapper'>
      <label htmlFor={name}>{label}</label>
      <input
        onChange={onChange}
        value={value}
        id={name}
        name={name}
        {...otherProps}
      />
    </div>
  );
};

export default Input;
