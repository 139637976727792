import type { Currency } from '../currencies/currencies';

export type CurrencyName = {
  coin: Currency;
  coin_name: string;
}

export const CurrencyNames: CurrencyName[] = [
  { coin: 'btc', coin_name: 'Bitcoin' },
  { coin: 'eth', coin_name: 'Ethereum' },
  { coin: 'xrp', coin_name: 'Ripple' },
  { coin: 'usdt', coin_name: 'Tether' },
  { coin: 'ltc', coin_name: 'Litecoin' },
  { coin: 'xlm', coin_name: 'Stellar' },
  { coin: 'bch', coin_name: 'Bitcoin Cash' },
  { coin: 'trx', coin_name: 'Tron' },
  { coin: 'uni', coin_name: 'Uniswap' },
  { coin: 'usdc', coin_name: 'USD Coin' },
  { coin: 'busd', coin_name: 'Binance USD' },
  { coin: 'bnb', coin_name: 'Binance Coin' },
  { coin: 'reef', coin_name: 'Reef' },
  { coin: 'shib', coin_name: 'Shiba Inu' },
  { coin: 'doge', coin_name: 'Dogecoin' },
  { coin: 'btt', coin_name: 'BitTorrent' },
  { coin: 'usd', coin_name: 'United States Dollar' },
  { coin: 'eur', coin_name: 'Euro' },
  { coin: 'gel', coin_name: 'Georgian Lari' },
  { coin: 'rub', coin_name: 'Russian Ruble'}
];