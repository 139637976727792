import React from "react";
import "./CoinStatusCard.css";

const CoinStatusCard = ({ coin }) => {
  const { symbol, pbx_last_block, explorer_last_block, block_diff, status } =
    coin;

  return (
    <div
      className={
        status ? "coin-status-card bg-success" : "coin-status-card bg-warning"
      }
    >
      <p className="p">
        <span>Coin: </span> <span> {symbol} </span>
      </p>
      <p className="p">
        <span>PBX Last Block: </span>
        <span>{pbx_last_block}</span>
      </p>
      <p className="p">
        <span>Explorer Last Block: </span>
        <span>{explorer_last_block}</span>
      </p>
      <p className="p">
        <span>Block Difference: </span>
        <span>{block_diff}</span>
      </p>
      <p className="p">
        <span>Status: </span>
        <span>{status ? "Working" : "Failed"}</span>
      </p>
    </div>
  );
};

export default CoinStatusCard;
