// @ts-check
import * as React from "react";
import * as Tokens from "../styles";
import darkTheme from "../styles/js/dark-theme";
import lightTheme from "../styles/js/light-theme";
import { useSelector } from "react-redux";

export default function useThemeVariables() {
  // @ts-ignore
  const [isDarkTheme, setIsDarkTheme] = React.useState(true);
  const theme = localStorage.getItem("theme");
  React.useEffect(() => {
    const updateTheme = (theme) => {
      theme === "dark" ? setIsDarkTheme(true) : setIsDarkTheme(false);
    };
    window.addEventListener("storage", () => updateTheme(theme));
    return () =>
      window.removeEventListener("storage", () => updateTheme(theme));
  }, [theme]);

  return React.useMemo(() => {
    const themeVaraibles = isDarkTheme ? darkTheme : lightTheme;

    return { ...Tokens, ...themeVaraibles, isDarkTheme };
  }, [isDarkTheme]);
}
