// @ts-check
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import * as storage from "../providers/storage";

/**
 * @typedef UserTransaction
 * @property {string} id
 * @property {number} user_id
 * @property {string} amountfrom
 * @property {number} amount
 * @property {null | "exchange" | "invoice" | "deposit" | "withdrawal" | "p2p"} type
 * @property {import('../assets/currencies/currencies').Currency | `${import('../assets/currencies/currencies').Currency} -> ${import('../assets/currencies/currencies').Currency}`} currency
 * @property {"pending" | "fail" | "success"} status
 * @property {string} network
 * @property {string} createdAt
 * @property {object} price
 *   @property {number} price.btc
 *   @property {number} price.usd
 *   @property {number} price.eur
 *   @property {number} price.rub
 *
 * @typedef GetUserTransactionsPayload
 * @property {number} page
 * @property {UserTransaction["type"]} type
 * @property {import('../assets/currencies/currencies').Currency[]} currencies
 * @property {null | Date} from
 * @property {null | Date} to
 * @property {string} txId (Transaction ID)
 * @property {string} address
 * @property {number} user_id
 */

/** @param {GetUserTransactionsPayload} payload */
export async function getUserTransactions(payload) {
  /** @type {import('axios').AxiosResponse<{status, message, data: {page: number, pages: number, items: UserTransaction[]}}>} */
  const token = storage.get("token");
  // @ts-ignore
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URI}manager/transactions`,
    data: payload,
    headers: {
      Authorization: token,
    },
  });

  if (!response) {
    throw new Error("Unexpected Error");
  }

  return response.data;
}

/**
 *  @param {Omit<GetUserTransactionsPayload, "page">} payload
 *  @param {{enabled?: boolean, retry?: boolean, onSuccess?: any, onError?: any}} [options]
 */
export function useUserTransactionsQuery(payload, options) {
  return useInfiniteQuery({
    queryKey: ["userTransactions", payload],
    // fetch first page by default
    queryFn: ({ pageParam = { ...payload, page: 1 } }) => {
      return getUserTransactions(pageParam);
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.data.page < lastPage.data.pages) {
        return {
          ...payload,
          page: lastPage.data.page + 1,
        };
      }
    },
    keepPreviousData: true,
    ...options,
  });
}
