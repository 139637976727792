import React, { useEffect, useState } from "react";
import "./Users.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTable } from "react-table";

import Loader from "../components/UI/Loader";

import { fetchUsersStart } from "../redux/users/users.actions";

import { ImCross } from "react-icons/im";
import { BsCheckLg } from "react-icons/bs";

const Users = ({ fetchUsers, users, loading }) => {
  let navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (users.length) {
      // following logic should go to redux utils
      const _data = users.map((user) => {
        const {
          PersonalAccount: { first_name, last_name },
          email,
          is_admin,
          withdrawal_limit,
          id,
        } = user;

        return {
          email,
          isAdmin: is_admin ? <BsCheckLg /> : <ImCross />,
          firstName: first_name,
          lastName: last_name,
          withdrawalLimit: withdrawal_limit,
          id,
        };
      });

      setData(_data);
    }
  }, [users]);

  const onClickUser = (id) => {
    navigate(`/user/${id}`);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "email",
        accessor: "email", // accessor is the "key" in the data
      },
      {
        Header: "is admin",
        accessor: "isAdmin",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Withdrawal Limit",
        accessor: "withdrawalLimit",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  if (loading) return <Loader />;

  return (
    <div className="users page">
      {/* Start Table */}

      <table {...getTableProps()} className="users-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => onClickUser(row.original.id)}
                style={{
                  backgroundColor:
                    row.id % 2 === 0
                      ? "rgba(25, 28, 29, 0.5)"
                      : "rgba(224, 227, 227, 0.02)",
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* End Table */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.users,
  loading: state.users.loadingUsers,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsersStart()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Users);
