import { takeLatest, call, put, all } from "redux-saga/effects";
import axios from "axios";
import * as storage from "../../providers/storage";

import { FETCH_DASHBOARD_DATA_START } from "./dashboard.types";

import {
  fetchdashboardDataSuccess,
  fetchdashboardDataFail,
} from "./dashboard.actions";

export function* fetchDashboardDataAsync() {
  const token = storage.get("token");

  try {
    const { data } = yield axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URI}manager`,
      headers: {
        Authorization: token,
      },
    });

    yield put(fetchdashboardDataSuccess(data.data));
  } catch (error) {
    yield put(fetchdashboardDataFail(error));
  }
}

export function* fetchDashboardData() {
  yield takeLatest(FETCH_DASHBOARD_DATA_START, fetchDashboardDataAsync);
}

export function* dashboardSagas() {
  yield all([call(fetchDashboardData)]);
}
