// @ts-check
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import * as storage from "../providers/storage";

/**
 * @typedef Settings
 *
 * @property {Array[object]} settingsData
 * @property {string} message
 * @property {string} status
 */

/**
 * @returns {Promise<Settings>}
 */

export async function getSettings() {
  const { data } = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URI}manager/config`,
    headers: {
      Authorization: storage.get("token"),
    },
  });

  return data;
}

/**
 * @param {{enabled: boolean}} [options]
 * @returns {import('@tanstack/react-query').UseQueryResult<Settings, Error>}
 **/

export function useSettingsQuery(options) {
  return useQuery(["settings"], getSettings, options);
}
