import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./Dashboard.css";
import CardsContainer from "../containers/CardsContainer";
import Card from "../components/Card";
import Loader from "../components/UI/Loader";
import { fetchdashboardDataStart } from "../redux/dashboard/dashboard.actions";
import { fetchTransactionsStart } from "../redux/transactions/transactions.actions";
import { fetchTotalBalanceStart } from "redux/balance/balance.actions";

const Dashboard = ({
  dashboardData,
  fetchdashboardData,
  loading,
  fetchTransactions,
  fetchTotalBalanceStart,
  totalBalance,
  loadingTotalBalance,
}) => {
  useEffect(() => {
    fetchdashboardData();
    fetchTransactions();
  }, [fetchdashboardData, fetchTransactions]);

  useEffect(() => {
    fetchTotalBalanceStart();
  }, [fetchTotalBalanceStart]);

  if (loading) return <Loader />;

  return (
    <div className="dashboard-page page">
      <h1 style={{ color: "var(--color-on-surface)" }}>Dashboard</h1>
      <CardsContainer>
        {Object.keys(dashboardData).length &&
          Object.keys(dashboardData).map((title) => {
            return (
              <Card key={title} title={title} count={dashboardData[title]} />
            );
          })}
      </CardsContainer>
      <CardsContainer>
        {loadingTotalBalance ? (
          <Loader />
        ) : (
          <>
            {totalBalance["SUM"]?.total && (
              <Card
                key={1}
                title={"Wallet Balance (USD)"}
                count={totalBalance["SUM"].total}
              />
            )}
            {totalBalance["SUM"]?.exchange_totals && (
              <Card
                key={2}
                title={"Exchange Totals (USD)"}
                count={totalBalance["SUM"].exchange_totals}
              />
            )}
            {totalBalance["SUM"]?.address_totals && (
              <Card
                key={3}
                title={"Address Totals (USD)"}
                count={totalBalance["SUM"].address_totals}
              />
            )}
            {totalBalance["SUM"]?.netGain && (
              <Card
                key={4}
                title={"Net Gain Sum (USD)"}
                count={totalBalance["SUM"].netGain}
              />
            )}
          </>
        )}
      </CardsContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dashboardData: state.dashboard.dashboardData,
  loading: state.dashboard.loadingdashboardData,
  loadingTotalBalance: state.balance.loadingTotalBalance,
  totalBalance: state.balance.totalBalance,
});

const mapDispatchToProps = (dispatch) => ({
  fetchdashboardData: () => dispatch(fetchdashboardDataStart()),
  fetchTransactions: () => dispatch(fetchTransactionsStart()),
  fetchTotalBalanceStart: () => dispatch(fetchTotalBalanceStart()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Dashboard);
