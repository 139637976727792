import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./User.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../components/UI/Loader";
import {
  fetchUserStart,
  updateUserWithdrawalLimitStart,
} from "../redux/users/users.actions";
import { ImCross } from "react-icons/im";
import { BsBackspace, BsCheckLg } from "react-icons/bs";
import TransactionsPage from "components/Transactions/Transactions.page";

const User = ({ fetchUser, user, loading, updateWithdrawalLimit }) => {
  let params = useParams();
  const [limitAmount, setLimitAmount] = React.useState(0);

  useEffect(() => {
    if (params?.id) {
      fetchUser(params.id);
    }
  }, [params, fetchUser]);

  if (loading) return <Loader />;

  return (
    <div className="user page">
      <Link
        to={"/users"}
        style={{
          textDecoration: "none",
          color: "var(--color-on-surface)",
          marginLeft: "4rem",
        }}
      >
        <BsBackspace size="2.5rem" />
      </Link>
      {Object.keys(user).length &&
        Object.keys(user).map((key) => {
          return (
            <div key={key} className="user-info-row">
              <span className="user-info-row__title">
                {key.split("_").join(" ")}:{" "}
              </span>

              {key === "is_admin" ? (
                <span className="user-info-row__data">
                  {user[key] ? <BsCheckLg /> : <ImCross />}
                </span>
              ) : key === "withdrawal_limit" ? (
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                  }}
                >
                  <input
                    // @ts-ignore
                    onChange={(e) => setLimitAmount(e.target.value)}
                    value={limitAmount}
                  />
                  <button
                    onClick={() =>
                      updateWithdrawalLimit({
                        amount: limitAmount,
                        userId: user.id,
                      })
                    }
                  >
                    Update Limit
                  </button>
                  <span className="user-info-row__data">
                    {"Current Value: "}
                    {user[key]}
                  </span>
                </div>
              ) : (
                <span className="user-info-row__data">{user[key]}</span>
              )}
            </div>
          );
        })}
      <TransactionsPage />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  loading: state.users.loadingUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (id) => dispatch(fetchUserStart(id)),
  updateWithdrawalLimit: (payload) =>
    dispatch(updateUserWithdrawalLimitStart(payload)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(User);
