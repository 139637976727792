// List of currencies supported by us, to be used as constants or types.
// Update and add currencies here as the project goes on.

export const fiatCurrencies = [
  'usd',
  'eur',
  'gel',
  'rub'
] as const;

export const cryptoCurrencies = [
  'bch',
  'btc',
  'busd',
  'bnb',
  'doge',
  'eth',
  'ltc',
  'reef',
  'shib',
  'uni',
  'usdc',
  'usdt',
  'xlm',
  'xrp',
  'trx',
  'btt',
] as const;

export const currencies = [...fiatCurrencies, ...cryptoCurrencies] as const;

export type FiatCurrency = typeof fiatCurrencies[number];

export type CryptoCurrency = typeof cryptoCurrencies[number];

export type Currency = typeof currencies[number];
