import {
  FETCH_EXCHANGE_TOTALS_START,
  FETCH_EXCHANGE_TOTALS_SUCCESS,
  FETCH_EXCHANGE_TOTALS_FAIL,
} from "./exchange.types";

const INIT_STATE = {
  exchangeTotals: {},
  loadingExchangeTotals: false,
  exchangeTotalsError: null,
};

const exchangeReducer = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_EXCHANGE_TOTALS_START:
      return {
        ...state,
        loadingExchangeTotals: true,
      };

    case FETCH_EXCHANGE_TOTALS_SUCCESS:
      return {
        ...state,
        loadingExchangeTotals: false,
        exchangeTotals: payload,
      };

    case FETCH_EXCHANGE_TOTALS_FAIL:
      return {
        ...state,
        loadingExchangeTotals: false,
        exchangeTotalsError: payload,
      };
    default:
      return state;
  }
};

export default exchangeReducer;
