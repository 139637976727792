import React from 'react';
import './Sweeper.css';
import axios from 'axios';
import { Button } from '../components/UI/Buttons';
import toast from 'react-hot-toast';
import * as storage from '../providers/storage';

const Sweeper = ({ fetchLatestBlocks, latestBlocks }) => {
  const onSweep = async (coin) => {
    const token = storage.get('token');
    const promise = axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URI}manager/transactions/sweep`,
      data: {
        coin,
      },
      headers: {
        Authorization: token,
      },
    });
    toast.promise(promise, {
      loading: `${coin.toUpperCase()}  Sweeper in progress...`,
      success: <b>run sweeper successfuly</b>,
      error: <b>Something went wrong</b>,
    });
  };

  return (
    <div className='page sweeper'>
      <div className='util'>
        <div className='util__main-address'>
          {' '}
          Main TRX Address:{' '}
          <span
            onClick={() =>
              window.open(
                'https://tronscan.org/#/address/TQga7FBUR93wpXdPbRbkx7dr7enH89pGUF',
                '_blank'
              )
            }
          >
            "TQga7FBUR93wpXdPbRbkx7dr7enH89pGUF"
          </span>
        </div>
        <Button text='TRX SWEEPER' click={() => onSweep('trx')} />
      </div>

      <div className='util'>
        <div className='util__main-address'>
          Main ETH Address:{' '}
          <span
            onClick={() =>
              window.open(
                'https://etherscan.io/address/0x1d185a91b7fa75a429e6eb8e2e4c3f74542b79e7',
                '_blank'
              )
            }
          >
            "0x1d185a91b7fa75a429e6eb8e2e4c3f74542b79e7"
          </span>
        </div>
        <Button text='ETH SWEEPER' click={() => onSweep('eth')} />
      </div>


      <div className='util'>
        <div className='util__main-address'>
          Main BNB Address:{' '}
          <span
            onClick={() =>
              window.open(
                'https://bscscan.com/address/0x5abd01990e2685b73b02256d329465601fd43505',
                '_blank'
              )
            }
          >
            "0x5abd01990e2685b73b02256d329465601fd43505"
          </span>
        </div>
        <Button text='BNB SWEEPER' click={() => onSweep('bnb')} />
      </div>
    </div>
  );
};

export default Sweeper;
